// extracted by mini-css-extract-plugin
export var border = "_2s-0";
export var borderSelected = "_2s-1";
export var check = "_2s-6";
export var circle = "_2s-2";
export var circleInner = "_2s-4";
export var circleInnerDisabled = "_2s-8";
export var description = "_2s-3";
export var horizontal = "_2s-b";
export var input = "_2s-5";
export var label = "_2s-9";
export var radioButton = "_2s-d";
export var radioButtonNoCenter = "_2s-e";
export var radioGroup = "_2s-a";
export var rightRadio = "_2s-f";
export var subcontent = "_2s-g";
export var uncheck = "_2s-7";
export var vertical = "_2s-c";