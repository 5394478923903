// extracted by mini-css-extract-plugin
export var actionButton = "_ah-1";
export var actions = "_ah-0";
export var copyableField = "_ah-2";
export var detailColumn = "_ah-3";
export var detailRow = "_ah-4";
export var dropdownTitle = "_ah-5";
export var fulfillmentDetails = "_ah-7";
export var fulfillments = "_ah-6";
export var icon = "_ah-8";
export var imgContainer = "_ah-9";
export var lineItems = "_ah-a";
export var link = "_ah-p";
export var orderCard = "_ah-k";
export var orderCollapseButton = "_ah-l";
export var orderCollapseButtonIcon = "_ah-m";
export var orderDetails = "_ah-b";
export var orderPillsContainer = "_ah-c";
export var pillWrapper = "_ah-d";
export var product = "_ah-e";
export var productDetails = "_ah-g";
export var productTopDetails = "_ah-h";
export var products = "_ah-f";
export var strong = "_ah-i";
export var tightText = "_ah-o";
export var tooltipHidden = "_ah-q";
export var variantQuantityRow = "_ah-j";
export var wrappingText = "_ah-n";