// extracted by mini-css-extract-plugin
export var actionButton = "_cd-0";
export var bubble = "_cd-2";
export var bubbleContext = "_cd-6";
export var bubbleItems = "_cd-7";
export var customer = "_cd-3";
export var divider = "_cd-8";
export var fileList = "_cd-9";
export var infoAbove = "_cd-a";
export var internal = "_cd-4";
export var lightActionButton = "_cd-1";
export var link = "_cd-b";
export var merchant = "_cd-5";
export var message = "_cd-c";
export var profilePicture = "_cd-e";
export var quillToolbarContainer = "_cd-f";
export var senderDate = "_cd-g";
export var senderDateCustomer = "_cd-h";
export var senderName = "_cd-d";
export var time = "_cd-i";
export var uploadedImage = "_cd-j";
export var username = "_cd-k";
export var xsmall = "_cd-l";