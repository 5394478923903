// extracted by mini-css-extract-plugin
export var buttons = "_2u-0";
export var buttonsActive = "_2u-h";
export var cancelConfirm = "_2u-1";
export var closeButton = "_2u-2";
export var column = "_2u-3";
export var container = "_2u-4";
export var detailHeader = "_2u-6";
export var detailTitle = "_2u-7";
export var detailsContent = "_2u-5";
export var header = "_2u-8";
export var headerWithClose = "_2u-9";
export var icon = "_2u-a";
export var infoIcon = "_2u-c";
export var informationContainer = "_2u-b";
export var sideDrawer = "_2u-d";
export var sideDrawerOpen = "_2u-e";
export var stickyFooter = "_2u-f";
export var typeOption = "_2u-g";