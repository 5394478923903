import { ExtendedAddress } from "@redotech/redo-model/address";
import { z } from "zod";
import { zExt } from "../common/zod-util";
import { Provider } from "../order";
import { ItemOrderBy } from "../outbound-labels";
import {
  ContentsType,
  NonDeliveryOption,
  USPSExemptionCode,
} from "../outbound-labels/customs";
import { LearnedPresetSchema } from "../outbound-labels/learned-preset";
import {
  IShipmentRates,
  OutboundRate,
  ShipmentRates,
  ShipmentRatesSchema,
  SpecialRateEligibility,
} from "../outbound-labels/outbound-labels";
import {
  getParcelWeightGrams,
  Parcel,
  ParcelSchema,
} from "../outbound-labels/parcel";
import {
  addWeight,
  convertWeight,
  MoneySchema,
  Weight,
  WeightSchema,
  WeightUnit,
} from "../outbound-labels/util";
import { OrderItemTableColumnData } from "../packing-slips/packing-slip-builder";
import { TagSchema } from "../tag";
import { CustomsDeclarationSchema } from "./customs-declaration";
import {
  carrierToLabel,
  getServiceLabel,
  SelectedShippingRate,
  SelectedShippingRateSchema,
} from "./fulfillment-carriers-and-services";
import { RedoShopifyDeliveryMethodType } from "./fulfillment-delivery-method-type";
import { FulfillmentGroupStatus } from "./fulfillment-group-status";
import { FulfillmentHoldSchema } from "./fulfillment-hold";
import { FulfillmentLineItem } from "./fulfillment-line-item";
import { LocationForMoveSchema } from "./fulfillment-location";
import {
  FulfillmentOrderAddressSchema,
  IFulfillmentOrderAddress,
  IVerifiedAddress,
  VerifiedAddressSchema,
} from "./fulfillment-order-address";
import {
  FulfillmentOrderLineItem,
  FulfillmentOrderLineItemSchema,
  IFulfillmentOrderLineItem,
} from "./fulfillment-order-line-item";
import { RedoShopifyFulfillmentOrderRequestStatus } from "./fulfillment-order-request-status";
import { RedoShopifyFulfillmentOrderStatus } from "./fulfillment-order-status";
import { PrintStatusSchema } from "./fullfilment-print-status";
import { MarketplaceNotificationStatusSchema } from "./marketplace-notification-status";
import { OMSUserSchema } from "./oms-user";
import { RedoShopifyOrderDisplayFinancialStatus } from "./order-display-financial-status";
import { OrderTransactionKind } from "./order-transaction-kind";
import {
  IRedoFulfillment,
  IShipmentPackage,
  RedoFulfillmentSchema,
  ShipmentPackageSchema,
} from "./outbound-shipment";
import { RiskSchema } from "./shopify-fulfillment-schemas";

export const FulfillmentGroupCustomsSchema = z.object({
  declarations: z.array(CustomsDeclarationSchema),
  overwritten: z.boolean(),
});
export type FulfillmentGroupCustoms = z.infer<
  typeof FulfillmentGroupCustomsSchema
>;
export interface IFulfillmentGroupCustoms
  extends z.ZodType<FulfillmentGroupCustoms> {}

export const OrderTransactionSchema = z.object({
  id: z.string(),
  formattedGateway: z.string().nullish(),
  gateway: z.string().nullish(),
  paymentDetails: z
    .object({
      company: z.string().nullish(),
      number: z.string().nullish(),
      lastFour: z.string().nullish(),
    })
    .nullish(),
  amount: MoneySchema,
  parentTransactionId: z.string().nullish(),
  kind: z.nativeEnum(OrderTransactionKind),
});
export type OrderTransaction = z.infer<typeof OrderTransactionSchema>;

export const FulfillmentOrderCustomerSchema = z.object({
  id: z.string(),
  name: z.string(),
  tags: z.array(z.string()).nullish(),
  numOrders: z.number(),
  email: z.string().nullish(),
  phone: z.string().nullish(),
  note: z.string().nullish(),
});

export type FulfillmentOrderCustomer = z.infer<
  typeof FulfillmentOrderCustomerSchema
>;

//https://lorefnon.me/2023/11/28/fixing-inferred-types-exceeding-serializable-length/
export interface IFulfillmentOrderCustomer
  extends z.ZodType<FulfillmentOrderCustomer> {}

export const FulfillmentOrderOrderSchema = z.object({
  id: z.string(),
  redoOrderId: z.string().nullish(),
  fullyPaid: z.boolean().nullish(),
  displayFinancialStatus: z
    .nativeEnum(RedoShopifyOrderDisplayFinancialStatus)
    .nullish(),
  name: z.string(),
  risk: RiskSchema.nullish(),
  tags: z.array(z.string()).nullish(),
  note: z.string(),
  customAttributes: z
    .array(z.object({ key: z.string(), value: z.string().nullish() }))
    .nullish(),
  createdAt: z.date(),
  shippingCost: MoneySchema,
  shippingCostPresented: MoneySchema.nullish(),
  closed: z.boolean().nullish(),
  closedAt: z.date().nullish(),
  salesChannel: z.string().nullish(),
  channelInformation: z
    .object({ app: z.object({ developerName: z.string().nullish() }) })
    .nullish(),
});
export type FulfillmentOrderOrder = z.infer<typeof FulfillmentOrderOrderSchema>;
export interface IFulfillmentOrderOrder
  extends z.ZodType<FulfillmentOrderOrder> {}

export const FulfillmentOrderSchema = z.object({
  provider: z.nativeEnum(Provider),
  externalId: z.string(),
  billingAddress: (
    FulfillmentOrderAddressSchema as IFulfillmentOrderAddress
  ).nullish(),
  originAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedOriginAddress: (VerifiedAddressSchema as IVerifiedAddress).nullish(),
  deliveryMethod: z.object({
    name: z.string().nullish(),
    code: z.string().nullish(),
    kind: z.nativeEnum(RedoShopifyDeliveryMethodType),
    instructions: z.string().nullish(),
    phone: z.string().nullish(),
  }),
  destinationAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedDestinationAddress: (
    VerifiedAddressSchema as IVerifiedAddress
  ).nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  fulfillAt: z.date().nullish(),
  fulfillBy: z.date().nullish(),
  lineItems: z.array(FulfillmentOrderLineItemSchema),
  transactions: z.array(OrderTransactionSchema).nullish(),
  locationsForMove: z.array(LocationForMoveSchema).nullish(),
  order: FulfillmentOrderOrderSchema.nullish(),
  customer: (
    FulfillmentOrderCustomerSchema as IFulfillmentOrderCustomer
  ).nullish(),
  requestStatus: z
    .nativeEnum(RedoShopifyFulfillmentOrderRequestStatus)
    .nullish(),
  status: z.nativeEnum(RedoShopifyFulfillmentOrderStatus),
  raw: z.any(),
});

export type FulfillmentOrder = z.infer<typeof FulfillmentOrderSchema>;
export interface IFulfillmentOrder extends z.ZodType<FulfillmentOrder> {}

const ShippingCostBreakdownSchema = z
  .object({
    fulfillmentOrdersBreakdown: z
      .array(
        z.object({
          labelCost: z.string(),
          markup: z
            .object({
              percentage: z.string().optional(),
              amount: z.string().optional(),
            })
            .optional(),
        }),
      )
      .optional(),
  })
  .merge(MoneySchema);
export type ShippingCostBreakdown = z.infer<typeof ShippingCostBreakdownSchema>;

export const FulfillmentGroupAddressesSchema = z.object({
  address: FulfillmentOrderAddressSchema,
  verifiedAddress: VerifiedAddressSchema.nullish(),
});
export type FulfillmentGroupAddresses = z.infer<
  typeof FulfillmentGroupAddressesSchema
>;
export interface IFulfillmentGroupAddresses
  extends z.ZodType<FulfillmentGroupAddresses> {}

export const FulfillmentGroupSummary = z.object({
  totalItemQuantity: z.number(),
  totalSkuCount: z.number(),
  totalLineItemCount: z.number().nullish(),
  totalWeightGrams: z.number(),
  totalWeightWithParcelGrams: z.number().nullish(),
  totalPrice: MoneySchema,
  totalPriceInt: z.number().nullish(),
  totalTax: MoneySchema,
  totalTaxInt: z.number().nullish(),
  totalShippingCost: ShippingCostBreakdownSchema,
  totalShippingCostInt: z.number().nullish(),
  providerCreatedAt: z.date(),
  providerUpdatedAt: z.date(),
  updatedAt: z.date().nullish(),
  deliveryMethod: z.nativeEnum(RedoShopifyDeliveryMethodType).nullish(),
  originAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedOriginAddress: (VerifiedAddressSchema as IVerifiedAddress).nullish(),
  destinationAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedDestinationAddress: (
    VerifiedAddressSchema as IVerifiedAddress
  ).nullish(),
  billingAddress: (
    FulfillmentOrderAddressSchema as IFulfillmentOrderAddress
  ).nullish(),
  customer: (
    FulfillmentOrderCustomerSchema as IFulfillmentOrderCustomer
  ).nullish(),
  orderCreatedAt: z.date().nullish(),
});

export type FulfillmentGroupSummary = z.infer<typeof FulfillmentGroupSummary>;
export interface IFulfillmentGroupSummary
  extends z.ZodType<FulfillmentGroupSummary> {}

export enum SortingCriteria {
  FASTEST = "FASTEST",
  CHEAPEST = "CHEAPEST",
}

export enum FulfilledBy {
  REDO = "Redo",
  EXTERNAL = "External",
}

export const RateSelectionCriteria = z.object({
  ratesToChooseFrom: z.array(SelectedShippingRateSchema),
  sortingCriteria: z.nativeEnum(SortingCriteria),
});
export type RateSelectionCriteria = z.infer<typeof RateSelectionCriteria>;

export enum SelectedShippingRateSetBy {
  USER = "user",
  LEARNING = "learning",
  AUTOMATIONS = "automations",
  DEFAULT_CHEAPEST = "default_cheapest",
  CHECKOUT_RATE_PURCHASED = "checkout_rate_purchased",
}

export enum OMSEventType {
  PACKING_SLIP_PRINTED = "packing_slip_printed",
  LABEL_PRINTED = "label_printed",
  LABEL_AND_PACKING_SLIP_PRINTED = "label_and_packing_slip_printed",
  PICK_LIST_PRINTED = "pick_list_printed",
  MERGED = "merged",
  AUTO_MERGED = "auto_merged",
  UNMERGED = "unmerged",
  CREATED_FROM_SPLIT = "created_from_split",
  SPLIT = "split",
  AUTO_SPLIT = "auto_split",
  LABEL_PURCHASED = "label_purchased",
  LABEL_VOIDED = "label_voided",
  VERIFIED = "verified",
  UNVERIFIED = "unverified",
}

export const OMSEventSchema = z.object({
  userId: zExt.objectId().nullish(),
  type: z.nativeEnum(OMSEventType),
  createdAt: z.date(),
  mergeOrders: z
    .array(z.object({ orderId: z.string(), orderName: z.string() }))
    .nullish(), // used for merged and unmerged events
  splitFromGroup: zExt.objectId().nullish(), // used for created_from_split events
});
export type OMSEvent = z.infer<typeof OMSEventSchema>;

export const FulfillmentGroupSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  summary: FulfillmentGroupSummary,
  batchId: z.string().nullish(),
  prettyBatchId: z.string().nullish(),
  status: z.nativeEnum(FulfillmentGroupStatus),
  fulfillmentOrders: z.array(FulfillmentOrderSchema as IFulfillmentOrder),
  shipmentRates: (ShipmentRatesSchema as IShipmentRates).nullish(),
  overrideWeight: WeightSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  selectedParcel: ParcelSchema.nullish(),
  selectedShippingRate: SelectedShippingRateSchema.nullish(),
  selectedShippingRateSetBy: z.nativeEnum(SelectedShippingRateSetBy).nullish(),
  rateSelectionCriteria: RateSelectionCriteria.nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  tags: z.array(TagSchema).nullish(),
  noteToBuyer: z.string().nullish(),
  noteFromBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  assignedUserId: z.string().nullish(),
  shipment: (ShipmentPackageSchema as IShipmentPackage).nullish(),
  fulfillments: z.array(RedoFulfillmentSchema as IRedoFulfillment).nullish(),
  printStatus: PrintStatusSchema.nullish(),
  learnedPresets: z.array(LearnedPresetSchema).nullish(),
  ratesPendingSince: z.date().nullish(),
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
  includeInsurance: z.boolean().nullish(),
  specialRateEligibility: z.nativeEnum(SpecialRateEligibility).nullish(),
  overrideDestinationAddresses: (
    FulfillmentGroupAddressesSchema as IFulfillmentGroupAddresses
  ).nullish(),
  overrideDeliveryMethod: z.nativeEnum(RedoShopifyDeliveryMethodType).nullish(),
  customs: FulfillmentGroupCustomsSchema.nullish(),
  contentsType: z.nativeEnum(ContentsType).nullish(),
  contentsExplanation: z.string().nullish(),
  nonDeliveryOption: z.nativeEnum(NonDeliveryOption).nullish(),
  uspsExemptionCode: z.nativeEnum(USPSExemptionCode).nullish(),
  itn: z.string().nullish(),
  taxIdentifierIds: z.array(z.string()).nullish(),
  mergedTo: zExt.objectId().nullish(),
  ratesErrorMessage: z.string().nullish(),
  mergeSuggestionId: z.string().nullish(), // was going to be an objectId, but you cannot use objectIds for atlas search facets :(
  suggestionRejected: z.boolean().nullish(),
  disableAutoMerge: z.boolean().nullish(),
  pointerToNextGroup: zExt.objectId().nullish(), // If voided, we maintain a pointer to the group that was created as a result of the void
  scanVerified: z
    .object({
      verifiedBy: OMSUserSchema.nullish(),
      verifiedAt: z.date().nullish(),
    })
    .nullish(),
  packingSlipTemplateId: zExt.objectId().nullish(), // Custom packing slip template ID for this fulfillment group
  holds: z.array(FulfillmentHoldSchema).nullish(),
  releaseHoldsAt: z.string().nullish(),
  duplicateOf: zExt.objectId().nullish(),
  autoMergeRanAt: z.date().nullish(),
  events: z.array(OMSEventSchema).nullish(),
  marketplaceNotification: MarketplaceNotificationStatusSchema.nullish(),
  ddp: z.boolean().nullish(),
  fulfilledBy: z.nativeEnum(FulfilledBy).nullish(),
});

export type FulfillmentGroup = z.infer<typeof FulfillmentGroupSchema>;
export interface IFulfillmentGroup extends z.ZodType<FulfillmentGroup> {}

export const FulfillmentGroupDataSchema = FulfillmentGroupSummary.extend({
  _id: z.string(),
  tags: z.array(TagSchema).nullish(),
  orders: z.array(
    z.object({
      redoOrderId: z.string().nullish(),
      name: z.string(),
      tags: z.array(z.string()),
      id: z.string(),
    }),
  ),
  /**
   * @deprecated Use fulfillmentOrderToDeliveryMethod instead
   */
  shippingMethodNames: z.array(z.string()),
  fulfillmentOrderToDeliveryMethod: z.record(
    z.string(),
    z.object({
      name: z.string(),
      code: z.string().nullish(),
      kind: z.string(),
    }),
  ),
  batchId: z.string().nullish(),
  prettyBatchId: z.string().nullish(),
  items: z.array(FulfillmentOrderLineItemSchema as IFulfillmentOrderLineItem),
  originAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedOriginAddress: (VerifiedAddressSchema as IVerifiedAddress).nullish(),
  destinationAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedDestinationAddress: (
    VerifiedAddressSchema as IVerifiedAddress
  ).nullish(),
  billingAddress: (
    FulfillmentOrderAddressSchema as IFulfillmentOrderAddress
  ).nullish(),
  status: z.nativeEnum(FulfillmentGroupStatus),
  selectedParcel: ParcelSchema.nullish(),
  customerShippingPaid: z.string().nullish(),
  availableShipmentRates: (ShipmentRatesSchema as IShipmentRates).nullish(),
  selectedShippingRate: SelectedShippingRateSchema.nullish(),
  overrideWeight: WeightSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  assignedUser: OMSUserSchema.nullish(),
  fulfillments: z.array(RedoFulfillmentSchema as IRedoFulfillment).nullish(),
  shipment: (ShipmentPackageSchema as IShipmentPackage).nullish(),
  noteToBuyer: z.string().nullish(),
  noteFromBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  customAttributes: z.array(
    z.object({ key: z.string(), value: z.string().nullish() }),
  ),
  customerName: z.string().nullish(),
  customerEmail: z.string().nullish(),
  printStatus: PrintStatusSchema.nullish(),
  orderSources: z.array(z.nativeEnum(Provider)),
  risk: RiskSchema.nullish(),
  ratesPendingSince: z.date().nullish(),
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
  includeInsurance: z.boolean().nullish(),
  specialRateEligibility: z.nativeEnum(SpecialRateEligibility).nullish(),
  ratesErrorMessage: z.string().nullish(),
  mergedTo: zExt.objectId().nullish(),
  pointerToNextGroup: zExt.objectId().nullish(), // If voided, we maintain a pointer to the group that was created as a result of the void
  insured: z.boolean().nullish(),
  fulfilledBy: z.nativeEnum(FulfilledBy).nullish(),
  stores: z.array(z.string()),
  scanVerified: z
    .object({
      verifiedBy: OMSUserSchema.nullish(),
      verifiedAt: z.date().nullish(),
    })
    .nullish(),
  packingSlipTemplateId: z.string().nullish(),
  fulfillmentOrderIds: z.array(z.string()),
  events: z.array(OMSEventSchema).nullish(),
  customs: FulfillmentGroupCustomsSchema.nullish(),
  marketplaceNotification: MarketplaceNotificationStatusSchema.nullish(),
  locationsForMove: z.array(LocationForMoveSchema).nullish(),
  selectedLocationId: z.string().nullable(),
  contentsType: z.nativeEnum(ContentsType).nullish(),
  contentsExplanation: z.string().nullish(),
  nonDeliveryOption: z.nativeEnum(NonDeliveryOption).nullish(),
  uspsExemptionCode: z.nativeEnum(USPSExemptionCode).nullish(),
  itn: z.string().nullish(),
  taxIdentifierIds: z.array(z.string()).nullish(),
  ddp: z.boolean().nullish(),
  duplicateOf: zExt.objectId().nullish(),
});

export type FulfillmentGroupData = z.infer<typeof FulfillmentGroupDataSchema>;
export interface IFulfillmentGroupData
  extends z.ZodType<FulfillmentGroupData> {}

export function sortFulfillmentOrderLineItems(
  orderBy: ItemOrderBy,
  items: FulfillmentOrderLineItem[],
): FulfillmentOrderLineItem[] {
  switch (orderBy) {
    case ItemOrderBy.QuantityDesc:
      return items.sort((a, b) => b.quantity - a.quantity);
    case ItemOrderBy.QuantityAsc:
      return items.sort((a, b) => a.quantity - b.quantity);
    case ItemOrderBy.NameAsc:
      return items.sort((a, b) => a.title.localeCompare(b.title));
    case ItemOrderBy.NameDesc:
      return items.sort((a, b) => b.title.localeCompare(a.title));
    case ItemOrderBy.SkuAsc:
      return items.sort((a, b) => (a.sku || "").localeCompare(b.sku || ""));
    case ItemOrderBy.SkuDesc:
      return items.sort((a, b) => (b.sku || "").localeCompare(a.sku || ""));
    case ItemOrderBy.TotalPriceAsc:
      return items.sort(
        (a, b) => Number(a.totalPrice.amount) - Number(b.totalPrice.amount),
      );
    case ItemOrderBy.TotalPriceDesc:
      return items.sort(
        (a, b) => Number(b.totalPrice.amount) - Number(a.totalPrice.amount),
      );
    case ItemOrderBy.UnitPriceAsc:
      return items.sort(
        (a, b) => Number(a.unitPrice.amount) - Number(b.unitPrice.amount),
      );
    case ItemOrderBy.UnitPriceDesc:
      return items.sort(
        (a, b) => Number(b.unitPrice.amount) - Number(a.unitPrice.amount),
      );
    case ItemOrderBy.BinAsc:
      return items.sort((a, b) => (a.bin || "").localeCompare(b.bin || ""));
    case ItemOrderBy.BinDesc:
      return items.sort((a, b) => (b.bin || "").localeCompare(a.bin || ""));
  }
}

export function sortFulfillmentLineItems(
  orderBy: ItemOrderBy,
  items: FulfillmentLineItem[],
): FulfillmentLineItem[] {
  switch (orderBy) {
    case ItemOrderBy.QuantityDesc:
      return items.sort((a, b) => b.quantity - a.quantity);
    case ItemOrderBy.QuantityAsc:
      return items.sort((a, b) => a.quantity - b.quantity);
    case ItemOrderBy.NameAsc:
      return items.sort((a, b) => a.title.localeCompare(b.title));
    case ItemOrderBy.NameDesc:
      return items.sort((a, b) => b.title.localeCompare(a.title));
    case ItemOrderBy.SkuAsc:
      return items.sort((a, b) => (a.sku || "").localeCompare(b.sku || ""));
    case ItemOrderBy.SkuDesc:
      return items.sort((a, b) => (b.sku || "").localeCompare(a.sku || ""));
    case ItemOrderBy.TotalPriceAsc:
      return items.sort(
        (a, b) => Number(a.totalPrice.amount) - Number(b.totalPrice.amount),
      );
    case ItemOrderBy.TotalPriceDesc:
      return items.sort(
        (a, b) => Number(b.totalPrice.amount) - Number(a.totalPrice.amount),
      );
    case ItemOrderBy.UnitPriceAsc:
      return items.sort(
        (a, b) =>
          Number(a.originalUnitPrice.amount) -
          Number(b.originalUnitPrice.amount),
      );
    case ItemOrderBy.UnitPriceDesc:
      return items.sort(
        (a, b) =>
          Number(b.originalUnitPrice.amount) -
          Number(a.originalUnitPrice.amount),
      );
    case ItemOrderBy.BinAsc:
      return items.sort((a, b) => (a.bin || "").localeCompare(b.bin || ""));
    case ItemOrderBy.BinDesc:
      return items.sort((a, b) => (b.bin || "").localeCompare(a.bin || ""));
  }
}

export function getWeightWithParcelGrams(
  totalWeightGrams: number,
  parcel: Parcel | null | undefined,
): Weight {
  const orderWeight: Weight = {
    unit: WeightUnit.GRAM,
    value: totalWeightGrams,
  };

  if (!parcel) {
    return orderWeight;
  }

  const parcelWeight = getParcelWeightGrams(parcel);

  const total = parcelWeight
    ? addWeight(parcelWeight, orderWeight)
    : orderWeight;

  return convertWeight(total, WeightUnit.GRAM);
}

export function getDisplayWeightGrams(
  overrideWeight: Weight | null | undefined,
  totalWeightWithParcelGrams: number,
): number {
  return overrideWeight
    ? convertWeight(overrideWeight, WeightUnit.GRAM).value
    : totalWeightWithParcelGrams;
}

export type FulfillmentGroupForPackingSlip = {
  fulfillmentGroupId: string;
  recipientFirstName: string;
  recipientFullName: string;
  orderItems: OrderItemTableColumnData[];
  destinationAddress: ExtendedAddress;
  originAddress: ExtendedAddress;
  //billingAddress: Address;
  trackingNumber: string | null;
  recipientPhone: string;
  recipientEmail: string;
  orderNumber: string;
  orderDate: string;
  packageWeight: { weight: number; unit: string };
  packageInformation: { name: string; dimensions: string[] };
  productsPrice: string;
  shippingPrice: string;
  tax: string;
  orderTotal: string;
  shippingCarrier: string;
  shippingService: string;
  requestedService: string;
  noteToCustomer: string;
  noteFromCustomer: string;
  salesChannel: string;
  totalQuantity: number;
  orderForReturnsPortal: FulfillmentOrder | undefined;
  fulfilledFromMultipleLocationsText: string;
};

export function getSelectedRate({
  selectedShippingRate,
  availableRates,
}: {
  selectedShippingRate: SelectedShippingRate | null | undefined;
  availableRates: ShipmentRates | null | undefined;
}): OutboundRate | undefined {
  if (!selectedShippingRate || !availableRates) {
    return undefined;
  }

  return (availableRates?.rates || []).find((rate) => {
    let condition =
      carrierToLabel[rate.carrier] ===
        carrierToLabel[selectedShippingRate.carrier] &&
      getServiceLabel(rate.service) ===
        getServiceLabel(selectedShippingRate.service);

    if (selectedShippingRate.carrier_account_id) {
      condition =
        condition &&
        rate.carrier_account_id === selectedShippingRate.carrier_account_id;
    }

    return condition;
  });
}

export function getFulfillmentOrdersByOrder(
  groups: FulfillmentGroup[],
): Record<string, FulfillmentOrder[]> {
  const byOrder = groups
    .flatMap((group) => group.fulfillmentOrders)
    .reduce(
      (acc, fulfillmentOrder) => {
        if (fulfillmentOrder.order) {
          const existing = acc[fulfillmentOrder.order.id] ?? [];
          existing.push(fulfillmentOrder);
          acc[fulfillmentOrder.order.id] = existing;
        }
        return acc;
      },
      {} as Record<string, FulfillmentOrder[]>,
    );
  return byOrder;
}

export function getFulfillmentGroupsOrderIds(
  groups: FulfillmentGroup[],
): string[] {
  const allOrders = groups
    .flatMap((group) => group.fulfillmentOrders.map((fo) => fo.order?.id))
    .filter((id): id is string => id !== undefined);
  const uniqueOrders = new Set(allOrders);
  return Array.from(uniqueOrders);
}
