// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_cz-0";
export var footer = "_cz-2";
export var infoSection = "_cz-3";
export var infoSectionContainer = "_cz-4";
export var infoSectionTitleRow = "_cz-5";
export var itemDetails = "_cz-6";
export var line = "_cz-7";
export var lineItemImage = "_cz-1";
export var lineItems = "_cz-8";
export var modalContent = "_cz-9";
export var multiLineInfo = "_cz-a";
export var orderName = "_cz-b";
export var strong = "_cz-c";