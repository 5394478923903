// extracted by mini-css-extract-plugin
export var bubbleContent = "_b8-0";
export var dark = "_b8-2";
export var dot = "_b8-7";
export var firstDot = "_b8-9";
export var left = "_b8-3";
export var light = "_b8-1";
export var loadingBubble = "_b8-5";
export var loadingDot = "_b8-c";
export var loadingDotContainer = "_b8-6";
export var moveUpDown = "_b8-8";
export var right = "_b8-4";
export var secondDot = "_b8-a";
export var thirdDot = "_b8-b";