// extracted by mini-css-extract-plugin
export var disabled = "_8-6";
export var hierarchyLinkGray = "_8-a";
export var hierarchyLinkUnderline = "_8-9";
export var hierarchyPrimary = "_8-0";
export var hierarchySecondary = "_8-7";
export var hierarchyTertiary = "_8-8";
export var pressed = "_8-2";
export var themeAction = "_8-1";
export var themeDestructive = "_8-4";
export var themeNormal = "_8-3";
export var themeSuccess = "_8-5";