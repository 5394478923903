// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_c9-f";
export var baseCustomerActivityCardArrowRightContainer = "_c9-e";
export var baseCustomerActivityCardContainer = "_c9-c";
export var baseCustomerActivityCardContainerHovered = "_c9-d";
export var conversationActivityDataConversationIconContainer = "_c9-5";
export var customerActivityDataClaimIcon = "_c9-8";
export var customerActivityDataClaimIconContainer = "_c9-7";
export var customerActivityDataConversationIcon = "_c9-6";
export var customerActivityDataConversationIconContainer = "_c9-4";
export var customerActivityDataOrderIcon = "_c9-1";
export var customerActivityDataOrderIconContainer = "_c9-0";
export var customerActivityDataReturnIcon = "_c9-3";
export var customerActivityDataReturnIconContainer = "_c9-2";
export var customerActivityShoppingIconContainer = "_c9-9";
export var messageBubble = "_c9-a";
export var messageBubbleText = "_c9-b";