// extracted by mini-css-extract-plugin
export var addButton = "_8u-0";
export var border = "_8u-8";
export var check = "_8u-h";
export var danger = "_8u-6";
export var dropdownContainer = "_8u-1";
export var editModalBody = "_8u-j";
export var footerContainer = "_8u-i";
export var fullWidth = "_8u-a";
export var hide = "_8u-9";
export var icon = "_8u-3";
export var input = "_8u-7";
export var inputFlex = "_8u-b";
export var inputWrapper = "_8u-c";
export var manualFocus = "_8u-5";
export var optionButton = "_8u-4";
export var pillWrapper = "_8u-e";
export var pointer = "_8u-2";
export var showOverflow = "_8u-f";
export var tagsFlex = "_8u-d";
export var xButton = "_8u-g";