import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { memo, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import { CSS } from "@dnd-kit/utilities";
import { useHandler } from "@redotech/react-util/hook";
import { useDebouncedHover } from "@redotech/react-util/use-debounced-hover";
import { deepCopyObject } from "@redotech/util/object";
import { Flex } from "../../../flex";
import { SortableTreeItem } from "./components/sortable-tree-item";
import { TreeItemType, type FlattenedItem, type TreeItem } from "./types";
import {
  buildTree,
  flattenTree,
  getProjection,
  removeChildrenOf,
} from "./utilities";

const measuring = { droppable: { strategy: MeasuringStrategy.Always } };

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface SortableTreeProps {
  items: TreeItem[];
  setItems: (items: TreeItem[]) => void;
  onRequestEdit: (id: UniqueIdentifier) => void;
}

const INDENTATION = 15;
const OPEN_FOLDER_ON_HOVER_DELAY = 750;

// Based off of this implementation: https://github.com/clauderic/dnd-kit/blob/master/stories/3%20-%20Examples/Tree/Tree.story.tsx
// The visual example is here: https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/examples-tree-sortable--collapsible
// React DND kit docs: https://docs.dndkit.com/introduction/installation
export const SortableTree = memo(function SortableTree({
  items,
  setItems,
  onRequestEdit,
}: SortableTreeProps) {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // drag only activates after the pointer moves 5 pixels
      },
    }),
  );

  const [expandedIds, setExpandedIds] = useState<Set<UniqueIdentifier>>(
    new Set(),
  );

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
      (acc, item: FlattenedItem) => {
        if (!expandedIds.has(item.id)) {
          return [...acc, item.id];
        }
        return acc;
      },
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId != null ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, items, expandedIds]);

  const projected = useMemo(() => {
    if (activeId && overId) {
      return getProjection(
        flattenedItems,
        activeId,
        overId,
        offsetLeft,
        INDENTATION,
      );
    }
    return null;
  }, [activeId, flattenedItems, offsetLeft, overId]);

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems],
  );
  const activeItem = useMemo(
    () => (activeId ? flattenedItems.find(({ id }) => id === activeId) : null),
    [activeId, flattenedItems],
  );

  const handleDragStart = useHandler(
    ({ active: { id: activeId } }: DragStartEvent) => {
      setActiveId(activeId);
      setOverId(activeId);
    },
  );

  const handleDragMove = useHandler(({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  });

  const handleDragOver = useHandler(({ over }: DragOverEvent) => {
    const newOverId = over?.id ?? null;
    setOverId(newOverId);
  });

  const handleDragEnd = useHandler(({ active, over }: DragEndEvent) => {
    resetDragState();

    if (projected && over) {
      const { depth, parentId } = projected;
      // Create a new flattened tree from the original items to ensure we have all children
      const flattenedTree = flattenTree(items);

      // Find the active and over items in the full tree
      const activeIndex = flattenedTree.findIndex(({ id }) => id === active.id);
      const overIndex = flattenedTree.findIndex(({ id }) => id === over.id);

      if (activeIndex !== -1 && overIndex !== -1) {
        // Create a complete clone of the flattened tree
        const clonedItems: FlattenedItem[] = flattenedTree.map((item) =>
          deepCopyObject(item),
        );

        // Find the parent in our cloned items if it exists
        const parentIndex = parentId
          ? clonedItems.findIndex(({ id }) => id === parentId)
          : -1;
        const parentItem = parentIndex !== -1 ? clonedItems[parentIndex] : null;
        if (parentItem?.type === TreeItemType.Folder) {
          handleExpand(parentItem.id.toString());
        }

        // Update the active item with new depth and parentId
        const activeTreeItem = clonedItems[activeIndex];
        clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

        // Move the item in the array and rebuild the tree
        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
        const newItems = buildTree(sortedItems);

        setItems(newItems);
      }
    }
  });

  const handleDragCancel = useHandler(() => {
    resetDragState();
  });

  // Define a helper to reset state without using the hover hook yet
  const resetDragState = useHandler(() => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
  });

  const handleExpand = useHandler((id: string) => {
    setExpandedIds((prev) => {
      const newSet = new Set(prev);
      newSet.add(id);
      return newSet;
    });
  });

  // Use the debounced hover hook for expanding folders on hover
  const { handleItemHover } = useDebouncedHover({
    delay: OPEN_FOLDER_ON_HOVER_DELAY,
    onDebounceComplete: handleExpand,
  });

  const handleToggleExpanded = useHandler((id: UniqueIdentifier) => {
    setExpandedIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  });

  useEffect(() => {
    if (!overId) {
      return;
    }

    const parentItem = flattenedItems.find(
      (item) => item.id === projected?.parentId,
    );

    // If the parent item is a collapsed folder, let's expand it after a delay
    if (
      parentItem?.type === TreeItemType.Folder &&
      !expandedIds.has(parentItem.id)
    ) {
      handleItemHover(parentItem.id.toString());
    } else {
      // If we're not hovering over a folder anymore, reset the hover state
      handleItemHover(null);
    }
  }, [overId, projected, flattenedItems, handleItemHover, expandedIds]);

  const getChildrenLinks = useHandler((item: FlattenedItem) => {
    return flattenTree(items)
      .filter((child) => child.parentId === item.id)
      .map((child) =>
        child.type === TreeItemType.View ? child.actionHref : null,
      )
      .filter((linkHref) => linkHref !== null);
  });

  return (
    <DndContext
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <Flex dir="column" gap="none">
          {flattenedItems.map((item: FlattenedItem) => (
            <SortableTreeItem
              childrenLinks={getChildrenLinks(item)}
              depth={
                item.id === activeId && projected ? projected.depth : item.depth
              }
              expanded={expandedIds.has(item.id)}
              indentationWidth={INDENTATION}
              key={item.id}
              onRequestEdit={() => onRequestEdit(item.id)}
              onToggleExpanded={() => handleToggleExpanded(item.id)}
              value={item}
            />
          ))}
        </Flex>
        {createPortal(
          <DragOverlay dropAnimation={dropAnimationConfig}>
            {activeId && activeItem ? (
              <SortableTreeItem
                clone
                depth={activeItem.depth}
                indentationWidth={INDENTATION}
                value={activeItem}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
});
