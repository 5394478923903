// extracted by mini-css-extract-plugin
export var box = "_44-0";
export var check = "_44-1";
export var checkboxButton = "_44-6";
export var checkboxGroup = "_44-5";
export var disabled = "_44-7";
export var ghost = "_44-9";
export var input = "_44-2";
export var label = "_44-4";
export var normal = "_44-8";
export var uncheck = "_44-3";