// extracted by mini-css-extract-plugin
export var active = "_cf-b";
export var arrow = "_cf-8";
export var arrowContainer = "_cf-4";
export var container = "_cf-0";
export var disabled = "_cf-5";
export var dot = "_cf-a";
export var dotContainer = "_cf-9";
export var leftArrowContainer = "_cf-6 _cf-4";
export var rightArrowContainer = "_cf-7 _cf-4";
export var slide = "_cf-3";
export var sliderContainer = "_cf-1";
export var sliderOverflow = "_cf-2";