import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import {
  ReloadTeamContext,
  TeamContext,
} from "@redotech/redo-merchant-app-common/team";
import { ShopifySubscriptionType } from "@redotech/redo-model/shopify-billing/shopify-subscription-types";
import { shopifySubscriptionMatchesAiSubscription } from "@redotech/redo-model/support/billing/support-ai-billing";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { assertNever } from "@redotech/util/type";
import { memo, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  confirmShopifySubscription,
  createShopifySubscription,
} from "../../../client/shopify-billing";
import { Notification } from "../notification";

export const newProductUrlParam = "new_product";

export const ShopifyBilling = memo(function ShopifyBilling() {
  const team = useContext(TeamContext);

  const location = useLocation();
  const navigate = useNavigate();

  const shopifyUrlParam = "charge_id";

  const dismissResultNotification = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(shopifyUrlParam);
    navigate({ search: searchParams.toString() });
  };

  if (!team) {
    return null;
  }

  const queryParams = new URLSearchParams(location.search);
  const chargeId = queryParams.get(shopifyUrlParam);

  const shopifySubscription = team.settings.shopifySubscription?.[0];

  const usageComponent = shopifySubscription?.lineItems
    .map((item) => item.plan.pricingDetails)
    .find((pricingDetails) => pricingDetails.__typename === "AppUsagePricing");

  const activeBillableProducts: ShopifySubscriptionType[] = [];

  if (
    (team.settings.support?.billing?.base?.pricePerMonthInCents || 0) > 0 &&
    !team.settings.support?.billing?.base?.subscriptionActive
  ) {
    activeBillableProducts.push(ShopifySubscriptionType.SUPPORT_BASE);
  }

  const emailMarketingBilling = team.settings.marketing?.emailBilling;
  const smsMarketingBilling = team.settings.marketing?.smsBilling;
  if (emailMarketingBilling?.enabled || smsMarketingBilling?.enabled) {
    activeBillableProducts.push(ShopifySubscriptionType.MARKETING);
  }

  if (
    team.settings.orderTracking?.enabled &&
    team.settings.orderTracking?.billing?.enabled
  ) {
    activeBillableProducts.push(ShopifySubscriptionType.ORDER_TRACKING);
  }
  if (
    team.settings.support?.ai?.enabled &&
    team.settings.support.billing?.ai?.planName
  ) {
    activeBillableProducts.push(ShopifySubscriptionType.SUPPORT_AI);
  }
  if (
    team.settings.support?.billing?.textMessaging?.enabled &&
    team.settings.support?.billing?.textMessaging?.subscriptionActive
  ) {
    activeBillableProducts.push(ShopifySubscriptionType.TEXT_MESSAGING);
  }
  if (
    team.settings.orderTracking?.postPurchaseUpsell?.enabled &&
    team.settings.orderTracking?.postPurchaseUpsell?.billing?.billingEnabled
  ) {
    activeBillableProducts.push(ShopifySubscriptionType.ONE_CLICK_UPSELL);
  }

  const checkoutOptimizationBilling =
    team.settings.checkoutOptimization?.checkoutOptimizationBilling;
  if (checkoutOptimizationBilling?.enabled) {
    activeBillableProducts.push(ShopifySubscriptionType.CHECKOUT_OPTIMIZATION);
  }

  if (chargeId) {
    return (
      <ConfirmationNotification
        activeBillableProducts={activeBillableProducts}
        chargeId={chargeId}
        onDismiss={dismissResultNotification}
      />
    );
  }

  if (!usageComponent && activeBillableProducts.length > 0) {
    const acceptString = `Accept billing to gain access to the following features: ${activeBillableProducts.map(productName).join(", ")}`;
    return (
      <Notification type="warning">
        <p>{acceptString}</p>
        <SetupBillingButton>Accept billing</SetupBillingButton>
      </Notification>
    );
  }

  const aiSubscriptionConfirmed =
    shopifySubscriptionMatchesAiSubscription(team);

  if (!aiSubscriptionConfirmed) {
    return (
      <Notification type="warning">
        <p>
          Your Shopify subscription has been modified. Please review and accept
          the new subscription.
        </p>
        <SetupBillingButton>Review subscription</SetupBillingButton>
      </Notification>
    );
  }

  const capRaiseUrl =
    team.settings.shopifySubscriptionMetadata?.pendingCapRaiseUrl;
  if (capRaiseUrl) {
    return (
      <Notification type="warning">
        <p>You are approaching or have hit your Shopify usage limit.</p>
        <Button
          onClick={() => {
            window.location.href = capRaiseUrl;
          }}
          size={ButtonSize.EXTRA_SMALL}
          theme={ButtonTheme.PRIMARY}
        >
          Raise limit
        </Button>
      </Notification>
    );
  }

  return null;
});

function productName(type: ShopifySubscriptionType): string {
  switch (type) {
    case ShopifySubscriptionType.SUPPORT_BASE:
      return "Support";
    case ShopifySubscriptionType.SUPPORT_AI:
      return "Support AI";
    case ShopifySubscriptionType.ORDER_TRACKING:
      return "Order Tracking";
    case ShopifySubscriptionType.TEXT_MESSAGING:
      return "SMS/MMS";
    case ShopifySubscriptionType.ONE_CLICK_UPSELL:
      return "One-click upsell";
    case ShopifySubscriptionType.MARKETING:
      return "Marketing";
    case ShopifySubscriptionType.CHECKOUT_OPTIMIZATION:
      return "Checkout optimization";
    default:
      assertNever(type);
  }
}

const ConfirmationNotification = memo(function ConfirmationNotification({
  activeBillableProducts,
  chargeId,
  onDismiss,
}: {
  activeBillableProducts: ShopifySubscriptionType[];
  chargeId: string | undefined;
  onDismiss: () => void;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const reloadTeamContext = useContext(ReloadTeamContext);

  const [confirmLoad, doConfirm] = useTriggerLoad(async (signal) => {
    if (!chargeId) return false;
    let appSubscriptions = [];
    const queryParams = new URLSearchParams(window.location.search);
    const newProducts = queryParams.getAll(newProductUrlParam);
    const productsEnabling = newProducts.length
      ? (newProducts as ShopifySubscriptionType[])
      : activeBillableProducts;
    appSubscriptions = await confirmShopifySubscription(client, {
      chargeId,
      productsEnabling,
      signal,
    });
    reloadTeamContext?.();
    return appSubscriptions?.length > 0;
  });

  useEffect(() => {
    if (!chargeId) return;
    doConfirm();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeId]);

  return confirmLoad.pending ? (
    <Notification type="info">
      <p>Confirming Shopify subscription...</p>
    </Notification>
  ) : (
    <>
      {confirmLoad.value ? (
        <Notification type="success">
          <p>Shopify subscription has been confirmed.</p>
          <Button
            onClick={onDismiss}
            size={ButtonSize.EXTRA_SMALL}
            theme={ButtonTheme.PRIMARY}
          >
            Dismiss
          </Button>
        </Notification>
      ) : (
        <Notification type="error">
          <p>Failed to confirm Shopify subscription.</p>
          <SetupBillingButton>Try again</SetupBillingButton>
        </Notification>
      )}
    </>
  );
});

// In the future if we want to use this for other billing confirmations
// it should be refactored to be more generic than just order tracking.
const SetupBillingButton = memo(function SetupBillingButton({
  children,
}: {
  children: React.ReactNode;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [createLoad, doCreate] = useTriggerLoad(async (signal) => {
    const res = await createShopifySubscription(client, { signal });
    const url = res.confirmationUrl;
    if (url) {
      window.location.href = url;
    }
  });

  return (
    <Button
      onClick={() => doCreate()}
      pending={createLoad.pending}
      size={ButtonSize.EXTRA_SMALL}
      theme={ButtonTheme.PRIMARY}
    >
      {children}
    </Button>
  );
});
