// extracted by mini-css-extract-plugin
export var chip = "_1s-9";
export var chipLabel = "_1s-a";
export var close = "_1s-7";
export var closeIcon = "_1s-8";
export var error = "_1s-6";
export var infoTooltip = "_1s-2";
export var innerContainer = "_1s-1";
export var input = "_1s-b";
export var medium = "_1s-5";
export var outerContainer = "_1s-0";
export var small = "_1s-4";
export var xSmall = "_1s-3";