// extracted by mini-css-extract-plugin
export var addButton = "_5p-0";
export var border = "_5p-8";
export var check = "_5p-h";
export var danger = "_5p-6";
export var dropdownContainer = "_5p-1";
export var editModalBody = "_5p-j";
export var footerContainer = "_5p-i";
export var fullWidth = "_5p-a";
export var hide = "_5p-9";
export var icon = "_5p-3";
export var input = "_5p-7";
export var inputFlex = "_5p-b";
export var inputWrapper = "_5p-c";
export var manualFocus = "_5p-5";
export var optionButton = "_5p-4";
export var pillWrapper = "_5p-e";
export var pointer = "_5p-2";
export var showOverflow = "_5p-f";
export var tagsFlex = "_5p-d";
export var xButton = "_5p-g";