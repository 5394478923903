import {
  AmazonServices,
  Carriers,
  FedexServices,
  UpsServices,
  UspsServices,
} from "../../fulfillments/fulfillment-carriers-and-services";
import { SortingCriteria } from "../../fulfillments/fulfillment-group";
import { ShopifyFulfillmentHoldReason } from "../../fulfillments/shopify-fulfillment-schemas";
import { SpecialRateEligibility } from "../../outbound-labels/outbound-labels";
import { Parcel } from "../../outbound-labels/parcel";
import { Weight } from "../../outbound-labels/util";
import { Tag } from "../../tag";
import { TimeUnit } from "../../time";
import { BaseStep, StepType } from "../common";

export enum OmsActionType {
  SET_SHIPPING_SERVICE = "SET_SHIPPING_SERVICE",
  SET_PARCEL = "SET_PARCEL",
  SET_SHIPPING_SERVICE_CHEAPEST = "SET_SHIPPING_SERVICE_CHEAPEST",
  SET_SHIPPING_SERVICE_FASTEST = "SET_SHIPPING_SERVICE_FASTEST",
  SET_PACKING_SLIP_TEMPLATE = "SET_PACKING_SLIP_TEMPLATE",
  ADD_TAG = "ADD_TAG",
  SET_SHIPPING_OPTIONS = "SET_SHIPPING_OPTIONS",
  PLACE_HOLD = "PLACE_HOLD",
  PLACE_HOLD_UNTIL = "PLACE_HOLD_UNTIL",
  SET_WEIGHT = "SET_WEIGHT",
  SET_ASSIGNED_USER = "SET_ASSIGNED_USER",
  SET_DUTIES_PAYER = "SET_DUTIES_PAYER",
  SET_TAX_IDENTIFIERS = "SET_TAX_IDENTIFIERS",
}
/**
 * This model assumes actions will take the form of "Set ____ to ____"
 * This won't be the case for future actions that get added, so feel free to
 * edit this interface to have an "actionType" or something
 * search-tag:ADDING-AN-OMS-ACTION
 */
export interface BaseOmsActionStep extends BaseStep {
  type: StepType.OMS_ACTION;
  actionType: OmsActionType;
  field: OmsSettableFields;
  nextId: string;
}

export interface SetShippingServiceActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.SHIPPING_SERVICE;
  actionType: OmsActionType.SET_SHIPPING_SERVICE;
  newShippingService:
    | FedexServices
    | UpsServices
    | UspsServices
    | AmazonServices;
  newShippingCarrier: Carriers;
}

export interface SetShippingServiceCheapestActionStep
  extends BaseOmsActionStep {
  field: OmsSettableFields.SHIPPING_SERVICE_CHEAPEST;
  actionType: OmsActionType.SET_SHIPPING_SERVICE_CHEAPEST;
  servicesToChooseFrom: {
    service: FedexServices | UpsServices | UspsServices | AmazonServices;
    carrier: Carriers;
  }[];
  selectionCriteria: SortingCriteria.CHEAPEST;
}

export interface SetShippingServiceFastestActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.SHIPPING_SERVICE_FASTEST;
  actionType: OmsActionType.SET_SHIPPING_SERVICE_FASTEST;
  servicesToChooseFrom: {
    service: FedexServices | UpsServices | UspsServices | AmazonServices;
    carrier: Carriers;
  }[];
  selectionCriteria: SortingCriteria.FASTEST;
}

export interface SetParcelActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.PARCEL;
  actionType: OmsActionType.SET_PARCEL;
  parcel: Parcel;
}

export interface SetPackingSlipTemplateActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.PACKING_SLIP_TEMPLATE;
  actionType: OmsActionType.SET_PACKING_SLIP_TEMPLATE;
  packingSlipName: string;
  packingSlipTemplateId: string;
}

export interface AddTagActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.TAG;
  actionType: OmsActionType.ADD_TAG;
  tags: Tag[];
}

export interface ShippingOptions {
  signatureRequired?: boolean | null;
  adultSignatureRequired?: boolean | null;
  includeInsurance?: boolean | null;
  specialRateEligibility?: SpecialRateEligibility | null;
}

export interface SetShippingOptionsActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.SHIPPING_OPTIONS;
  actionType: OmsActionType.SET_SHIPPING_OPTIONS;
  shippingOptions: ShippingOptions;
}

export enum AutoHoldType {
  Date = "date",
  Duration = "duration",
}

export interface PlaceHoldActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.PLACE_HOLD;
  actionType: OmsActionType.PLACE_HOLD;
  holdOptions: {
    holdType: AutoHoldType.Duration;
    duration: number;
    timeUnit: TimeUnit;
    holdReason: ShopifyFulfillmentHoldReason;
    reasonNotes?: string | null;
  };
}

export interface PlaceHoldUntilActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.PLACE_HOLD_UNTIL;
  actionType: OmsActionType.PLACE_HOLD_UNTIL;
  holdOptions: {
    holdType: AutoHoldType.Date;
    date: Date;
    holdReason: ShopifyFulfillmentHoldReason;
    reasonNotes?: string | null;
  };
}

export interface SetWeightActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.SET_WEIGHT;
  actionType: OmsActionType.SET_WEIGHT;
  weight: Weight;
}

export interface SetAssignedUserActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.ASSIGNED_USER;
  actionType: OmsActionType.SET_ASSIGNED_USER;
  userId: string;
}

export interface SetDutiesPayerActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.DUTIES_PAYER;
  actionType: OmsActionType.SET_DUTIES_PAYER;
  ddp: boolean; // https://support.easypost.com/hc/en-us/articles/360054753472-What-is-the-difference-between-DDP-DAP-and-DDU
}

export interface SetTaxIdentifiersActionStep extends BaseOmsActionStep {
  field: OmsSettableFields.TAX_IDENTIFIERS;
  actionType: OmsActionType.SET_TAX_IDENTIFIERS;
  taxIdentifierIds: string[];
}

/**
 * Fields that can be set in an OMS automation action
 * search-tag:ADDING-AN-OMS-ACTION
 */
export enum OmsSettableFields {
  SHIPPING_SERVICE = "shipping_service",
  SHIPPING_SERVICE_CHEAPEST = "shipping_service_cheapest",
  SHIPPING_SERVICE_FASTEST = "SHIPPING_SERVICE_FASTEST",
  PARCEL = "parcel",
  PACKING_SLIP_TEMPLATE = "packing_slip_template",
  TAG = "tag",
  SHIPPING_OPTIONS = "shipping_options",
  PLACE_HOLD = "place_hold",
  PLACE_HOLD_UNTIL = "place_hold_until",
  SET_WEIGHT = "set_weight",
  ASSIGNED_USER = "assigned_user",
  DUTIES_PAYER = "duties_payer",
  TAX_IDENTIFIERS = "tax_identifiers",
}
