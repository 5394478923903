// extracted by mini-css-extract-plugin
export var alignCenter = "_55-d";
export var bannerShown = "_55-6";
export var buttonContent = "_55-0";
export var cardList = "_55-1";
export var centerContent = "_55-2";
export var chevron = "_55-3";
export var closePanelButton = "_55-k";
export var conversationContextContainer = "_55-l";
export var conversationDetailContainer = "_55-4";
export var detailWrapper = "_55-7";
export var dropdownButton = "_55-e";
export var dropdownTitle = "_55-9";
export var fullHeight = "_55-5";
export var hidden = "_55-a";
export var icon = "_55-b";
export var panel = "_55-i";
export var portalButtonsWrapper = "_55-c";
export var right = "_55-j";
export var tableSummaryContainer = "_55-8";
export var tableSummaryHeader = "_55-g";
export var threeDotsButton = "_55-f";
export var wide = "_55-h";