// extracted by mini-css-extract-plugin
export var addDetails = "_2d-l";
export var addEditButton = "_2d-b";
export var addressFields = "_2d-2";
export var animationContainer = "_2d-0";
export var closeButton = "_2d-p";
export var customerDetails = "_2d-x";
export var customerInformationWrapper = "_2d-6";
export var dropdowns = "_2d-4";
export var error = "_2d-k";
export var fields = "_2d-n";
export var flex = "_2d-m";
export var grow = "_2d-3";
export var icon = "_2d-d";
export var imageButton = "_2d-q";
export var imagesWrapper = "_2d-s";
export var itemDetails = "_2d-i";
export var itemSelectionHeader = "_2d-9";
export var label = "_2d-w";
export var lineItemImage = "_2d-j";
export var lineItemReturnedWrapper = "_2d-h";
export var lineItemWrapper = "_2d-g";
export var modalContent = "_2d-f";
export var modalFooter = "_2d-1";
export var multipleChoiceAnswer = "_2d-u";
export var multipleChoiceAnswersWrapper = "_2d-t";
export var orderButton = "_2d-z";
export var orderLabel = "_2d-10";
export var orderSelection = "_2d-y";
export var question = "_2d-v";
export var search = "_2d-e";
export var selectCheckbox = "_2d-a";
export var selectionWrapper = "_2d-8";
export var spinner = "_2d-c";
export var subheader = "_2d-5";
export var title = "_2d-7";
export var uploadedImage = "_2d-o";
export var uploadedImageWrapper = "_2d-r";