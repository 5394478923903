import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { RouteObject } from "react-router-dom";

const checkoutExperienceBreadcrumb = breadcrumbSlot();
const splitBreadcrumb = breadcrumbSlot();

export const conversionRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Conversion", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../../page");
      return {
        element: (
          <Page
            hideHeader={hideHeader}
            hideNavbar={hideNavbar}
            hidePadding={hidePadding}
          />
        ),
      };
    },
    children: [
      {
        path: "live-view",
        handle: { breadcrumb: "Live view" },
        async lazy() {
          const { LiveViewPage } = await import(
            "../../../conversion/live-view/live-view-page"
          );
          return { Component: LiveViewPage };
        },
      },
      {
        path: "ab-tests",
        handle: { breadcrumb: "A/B tests" },
        children: [
          {
            path: "create",
            async lazy() {
              const { CreateAbTestPage } = await import(
                "../../../conversion/ab-tests/create-ab-test-page"
              );
              return { Component: CreateAbTestPage };
            },
          },
          {
            path: "",
            index: true,
            async lazy() {
              const { AbTestsPage } = await import(
                "../../../conversion/ab-tests/ab-tests-page"
              );
              return { Component: AbTestsPage };
            },
          },
          {
            path: ":splitId",
            handle: { breadcrumb: splitBreadcrumb },
            async lazy() {
              const { AbTestDetailPage } = await import(
                "../../../conversion/ab-tests/detail-page/ab-test-detail-page"
              );
              return {
                element: <AbTestDetailPage breadcrumb={splitBreadcrumb} />,
              };
            },
          },
        ],
      },
      {
        path: "checkouts",
        handle: { breadcrumb: "Checkouts" },
        children: [
          {
            handle: { breadcrumb: checkoutExperienceBreadcrumb },
            path: ":checkoutExperienceId",
            lazy: async () => {
              const { CheckoutExperienceDetailPage } = await import(
                "../../../conversion/checkout-experiences/checkout-page/checkout-experience-detail-page"
              );
              return {
                element: (
                  <CheckoutExperienceDetailPage
                    breadcrumb={checkoutExperienceBreadcrumb}
                  />
                ),
              };
            },
          },
          {
            path: "",
            async lazy() {
              const { CheckoutExperiencePage } = await import(
                "../../../conversion/checkout-experiences/checkout-experience-page"
              );
              return { Component: CheckoutExperiencePage };
            },
          },
        ],
      },
      {
        path: "analytics",
        handle: { breadcrumb: "Analytics" },
        async lazy() {
          const { AnalyticsPage } = await import(
            "../../../conversion/analytics/analytics-page"
          );
          return { Component: AnalyticsPage };
        },
      },
    ],
  },
];

function isCreateAbTestPage(url: string): boolean {
  return /conversion\/ab-tests\/create/.test(url);
}

function hidePadding(url: string): boolean {
  return true;
}

function hideNavbar(url: string): boolean {
  return isCreateAbTestPage(url);
}

function hideHeader(url: string): boolean {
  return isCreateAbTestPage(url);
}
