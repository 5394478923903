import { z } from "zod";
import { CustomsDeclarationSchema } from "../fulfillments/customs-declaration";
import {
  CarrierSchema,
  ServiceSchema,
} from "../fulfillments/fulfillment-carriers-and-services";
import { FulfillmentOrderAddressSchema } from "../fulfillments/fulfillment-order-address";
import { ContentsType, NonDeliveryOption, USPSExemptionCode } from "./customs";
import { ParcelSchema } from "./parcel";
import { WeightSchema } from "./util";

export const RateSchema = z.object({
  id: z.string(),
  shipment_id: z.string(),
  rate: z.string(),
  currency: z.string(),
  carrier: CarrierSchema,
  service: ServiceSchema,
  delivery_date: z.string().nullish(),
  delivery_days: z.number().nullish(),
  delivery_date_guaranteed: z.boolean().nullish(),
  created_at: z.string(),
  updated_at: z.string(),
  carrier_account_id: z.string(),
  upcharge: z.string().nullish(),
  is_merchant_carrier_account: z.boolean().nullish(),
  billing_type: z.string().nullish(),
  provider_cost: z.string().nullish(),
  fedex_zone: z.number().nullish(),
  usps_zone: z.number().nullish(),
  mode: z.string().nullish(),
});

export type OutboundRate = z.infer<typeof RateSchema>;

export const OutboundLabelShippingAddressSchema = z.object({
  name: z.string().nullish(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  country: z.string().nullish(),
  country_code: z.string().nullish(),
  province: z.string().nullish(),
  province_code: z.string().nullish(),
  zip: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  company: z.string().nullish(),
  email: z.string().nullish(),
  phone: z.string(),
});

export enum SpecialRateEligibility {
  MEDIA_MAIL = "USPS.MEDIAMAIL",
  LIBRARY_MAIL = "USPS.LIBRARYMAIL",
  MEDIA_MAIL_AND_LIBRARY_MAIL = "USPS.MEDIAMAIL,USPS.LIBRARYMAIL",
}

export function includesMediaMail(
  eligibility: SpecialRateEligibility | undefined | null,
): boolean {
  return (
    eligibility === SpecialRateEligibility.MEDIA_MAIL ||
    eligibility === SpecialRateEligibility.MEDIA_MAIL_AND_LIBRARY_MAIL
  );
}

export function includesLibraryMail(
  eligibility: SpecialRateEligibility | undefined | null,
): boolean {
  return (
    eligibility === SpecialRateEligibility.LIBRARY_MAIL ||
    eligibility === SpecialRateEligibility.MEDIA_MAIL_AND_LIBRARY_MAIL
  );
}

export const ShipmentRatesSchema = z.object({
  easypostShipmentId: z.string().nullish(),
  parcel: ParcelSchema,
  mode: z.string(),
  rates: z.array(RateSchema),
  weight: WeightSchema,
  originAddress: FulfillmentOrderAddressSchema.nullish(),
  destinationAddress: FulfillmentOrderAddressSchema.nullish(),
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
  customsDeclarations: z.array(CustomsDeclarationSchema).nullish(),
  specialRateEligibility: z.nativeEnum(SpecialRateEligibility).nullish(),
  contentsType: z.nativeEnum(ContentsType).nullish(),
  contentsExplanation: z.string().nullish(),
  nonDeliveryOption: z.nativeEnum(NonDeliveryOption).nullish(),
  uspsExemptionCode: z.nativeEnum(USPSExemptionCode).nullish(),
  taxIdentifierIds: z.array(z.string()).nullish(),
  itn: z.string().nullish(),
  ddp: z.boolean().nullish(),
});
export type ShipmentRates = z.infer<typeof ShipmentRatesSchema>;
export interface IShipmentRates extends z.ZodType<ShipmentRates> {}
