// extracted by mini-css-extract-plugin
export var animationContainer = "_8e-0";
export var conversationContainer = "_8e-1";
export var detailsContent = "_8e-2";
export var message = "_8e-3";
export var modalContent = "_8e-4";
export var modalFooter = "_8e-6";
export var modalHeader = "_8e-5";
export var name = "_8e-7";
export var optionContainer = "_8e-8";
export var optionTitle = "_8e-9";
export var pillContainer = "_8e-a";
export var selectedConversationsContainer = "_8e-b";
export var selectedOptionContainer = "_8e-c";