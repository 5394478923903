// extracted by mini-css-extract-plugin
export var defaultWrapper = "_9p-3";
export var downloadButton = "_9p-4";
export var fileContainer = "_9p-7";
export var fileInfo = "_9p-5";
export var fileInfoWidthRestricted = "_9p-6";
export var fileType = "_9p-1";
export var fileTypeName = "_9p-2";
export var image = "_9p-0";
export var link = "_9p-9";
export var overflowEllipsis = "_9p-8";