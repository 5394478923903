// extracted by mini-css-extract-plugin
export var bubble = "_43-0";
export var container = "_43-3";
export var contrastBorder = "_43-4";
export var greyOut = "_43-2";
export var initialsWrapper = "_43-1";
export var large = "_43-9";
export var medium = "_43-8";
export var mediumSmall = "_43-7";
export var micro = "_43-5";
export var small = "_43-6";
export var tiny = "_43-b";
export var xTiny = "_43-a";