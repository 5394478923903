import { Currency } from "@redotech/money/currencies";
import { z } from "zod";
import { zExt } from "../common/zod-util";

export const CENT_TO_THOUSANDTH_CENTS = 1000;
export const DOLLAR_TO_CENTS = 100;

export enum ServiceInvoiceType {
  MARKETING_SMS = "marketing-sms",
  MARKETING_EMAIL = "marketing-email",
  SUPPORT_BASE = "support-base",
  CHECKOUT_OPTIMIZATION = "checkout-optimization",
}

export enum BillingPeriod {
  MONTHLY = "month",
  YEARLY = "year",
}

export enum CheckoutOptimizationBillingPeriod {
  BI_WEEKLY = "bi-weekly",
}

export const billingPeriodToFriendlyLower: Record<
  BillingPeriod | CheckoutOptimizationBillingPeriod,
  string
> = {
  [BillingPeriod.MONTHLY]: "monthly",
  [BillingPeriod.YEARLY]: "yearly",
  [CheckoutOptimizationBillingPeriod.BI_WEEKLY]: "bi-weekly",
};

export const billingPeriodToFriendlyUpper: Record<
  BillingPeriod | CheckoutOptimizationBillingPeriod,
  string
> = {
  [BillingPeriod.MONTHLY]: "Monthly",
  [BillingPeriod.YEARLY]: "Yearly",
  [CheckoutOptimizationBillingPeriod.BI_WEEKLY]: "Bi-weekly",
};

export function lineItemToString(lineItem: LineItem): string {
  if (lineItem.quantity === 1) {
    return `${lineItem.name}: $${lineItem.price}`;
  } else {
    return `${lineItem.name} \u00D7 ${lineItem.quantity}: $${lineItem.price}`;
  }
}

export type LineItem = z.infer<typeof lineItemSchema>;
const lineItemSchema = z.object({
  name: z.string(),
  /* Price in dollars. Minor units may be longer than 2 decimal places */
  price: z.number(),
  currency: z.literal(Currency.USD),
  quantity: z.number(),
  nameDescription: z.string().optional(),
  quantityDescription: z.string().optional(),
  typeHelper: z.string().optional(),
});

export type BaseServiceInvoice = z.infer<typeof baseServiceInvoiceSchema>;
export const baseServiceInvoiceSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  lineItems: z.array(lineItemSchema),
  billingDueDate: z.date(),
  billedSuccessfully: z.boolean(),
  billingAttempts: z.number(),
  dateOfLastBillingAttempt: z.date().optional().nullable(),
});

export type RecurringInvoiceData = z.infer<typeof recurringInvoiceDataSchema>;
const recurringInvoiceDataSchema = z.object({
  invoiceDateStart: z.date(),
  invoiceDateEnd: z.date(),
  periodBillingStart: z.date(),
  periodBillingEnd: z.date(),
});

export type MarketingSmsInvoice = z.infer<typeof marketingSmsInvoiceSchema>;
export const marketingSmsInvoiceSchema = baseServiceInvoiceSchema.extend({
  kind: z.literal(ServiceInvoiceType.MARKETING_SMS),
  recurringInvoiceData: recurringInvoiceDataSchema.optional(),
  totalSmsParts: z.number(),
  totalMmsParts: z.number(),
  totalCarrierFeesInThousandthCents: z.number(),
  overageSmsPriceInThousandthCents: z.number(),
  overageMmsPriceInThousandthCents: z.number(),
  creditsForUpcomingMonth: z.number(),
  refreshedAt: z.date(),
  twilioMessagesSent: z.number(),
});

export type MarketingEmailInvoice = z.infer<typeof marketingEmailInvoiceSchema>;
export const marketingEmailInvoiceSchema = baseServiceInvoiceSchema.extend({
  kind: z.literal(ServiceInvoiceType.MARKETING_EMAIL),
  recurringInvoiceData: recurringInvoiceDataSchema.optional(),
  freeEmailsForPeriod: z.number(),
  emailsSent: z.number(),
  pricePerThousandInCents: z.number(),
});

export type SupportBaseInvoice = z.infer<typeof supportBaseInvoiceSchema>;
export const supportBaseInvoiceSchema = baseServiceInvoiceSchema.extend({
  kind: z.literal(ServiceInvoiceType.SUPPORT_BASE),
  recurringInvoiceData: recurringInvoiceDataSchema.optional(),
});

export type CheckoutOptimizationInvoice = z.infer<
  typeof checkoutOptimizationInvoiceSchema
>;
export const checkoutOptimizationInvoiceSchema =
  baseServiceInvoiceSchema.extend({
    kind: z.literal(ServiceInvoiceType.CHECKOUT_OPTIMIZATION),
    recurringInvoiceData: recurringInvoiceDataSchema.optional(),
    ordersCreatedWithRedoShippingMethod: z.number(),
    billableOrdersCreated: z.number(),
    pricePerOrderInCents: z.number(),
  });

export const serviceInvoiceSchema = z.discriminatedUnion("kind", [
  marketingSmsInvoiceSchema,
  marketingEmailInvoiceSchema,
  supportBaseInvoiceSchema,
  checkoutOptimizationInvoiceSchema,
]);

export type ServiceInvoice = z.infer<typeof serviceInvoiceSchema>;
