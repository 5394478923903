import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { z } from "zod";

import {
  ProductSchema,
  VariantAnalyticsFilterSchema,
  VariantSchema,
  VariantSortSchema,
} from "./product-analytics.types";

export const getReturnAnalyticsByVariant = {
  input: z.object({
    productId: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    types: z.array(z.nativeEnum(ReturnTypeEnum)),
    sort: VariantSortSchema.optional(),
    filters: z.array(VariantAnalyticsFilterSchema).optional(),
  }),
  output: z.object({
    product: ProductSchema,
    variants: z.array(VariantSchema),
  }),
};

export type ReturnAnalyticsByVariant = z.infer<
  typeof getReturnAnalyticsByVariant.output
>;
