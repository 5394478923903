// extracted by mini-css-extract-plugin
export var box = "_34-1";
export var checked = "_34-5";
export var descriptionSpacer = "_34-9";
export var disabled = "_34-4";
export var icon = "_34-6";
export var input = "_34-0";
export var labelSpacer = "_34-8";
export var medium = "_34-3";
export var small = "_34-2";
export var text = "_34-7";