// extracted by mini-css-extract-plugin
export var aboveInput = "_av-0";
export var actionButton = "_av-5";
export var actionButtonContainer = "_av-1";
export var actionIcon = "_av-6";
export var aiInfoContainer = "_av-2";
export var attachmentWrapper = "_av-7";
export var bold = "_av-3";
export var checkIcon = "_av-8";
export var clickingRecipientBar = "_av-11";
export var commentThreadSwitch = "_av-a";
export var draftImage = "_av-b";
export var draftImageContainer = "_av-c";
export var draftImages = "_av-e";
export var editButton = "_av-g";
export var editor = "_av-f";
export var editorContainer = "_av-y";
export var editorErrorTooltipWrapper = "_av-9";
export var headerDivider = "_av-h";
export var icon = "_av-i";
export var info = "_av-j";
export var internal = "_av-n";
export var internalDivider = "_av-k";
export var internalMessage = "_av-l";
export var messageInputCard = "_av-m";
export var messageInputForm = "_av-o";
export var messageInputFormInternal = "_av-p";
export var options = "_av-q";
export var publicMessageCustomer = "_av-s";
export var publicMessageMerchant = "_av-r";
export var removeImageButton = "_av-d";
export var replyButtons = "_av-t";
export var showSignatureButton = "_av-u";
export var spinner = "_av-v";
export var verticalButtonDivider = "_av-4";
export var visibilitySelector = "_av-w";
export var warning = "_av-x";
export var wrappingText = "_av-z";
export var writingInternalNoteTopBorder = "_av-10";