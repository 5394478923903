// extracted by mini-css-extract-plugin
export var danger = "_46-6";
export var lightPink = "_46-a";
export var medium = "_46-j";
export var nearSquare = "_46-m";
export var neutralBlue = "_46-8";
export var neutralYellow = "_46-7";
export var noCapitalize = "_46-1";
export var normal = "_46-2";
export var pill = "_46-0";
export var primary = "_46-3";
export var primaryLight = "_46-4";
export var shadow = "_46-n";
export var small = "_46-k";
export var solidBlack = "_46-i";
export var solidGreen = "_46-d";
export var solidLightBlue = "_46-f";
export var solidNavy = "_46-e";
export var solidOrange = "_46-h";
export var solidPink = "_46-g";
export var solidRed = "_46-b";
export var solidWhite = "_46-9";
export var solidYellow = "_46-c";
export var success = "_46-5";
export var xsmall = "_46-l";