// extracted by mini-css-extract-plugin
export var createdDate = "_au-1";
export var divider = "_au-2";
export var headerDivider = "_au-3";
export var messagesCard = "_au-0";
export var messagesContainer = "_au-7";
export var messagesContainerWrapper = "_au-4";
export var pointerCapturingButtonBoxes = "_au-a";
export var showLeftPanelButton = "_au-9";
export var showMoreButton = "_au-c";
export var showMoreButtonContainer = "_au-b";
export var summaryTitle = "_au-6";
export var systemMessageCardWrapper = "_au-d";
export var topButtons = "_au-8";
export var withPadding = "_au-5";