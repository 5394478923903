import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { z } from "zod";

import {
  ProductAnalyticsFilterSchema,
  ProductSchema,
  ProductSortSchema,
  ReturnReasonSchema,
} from "./product-analytics.types";

export const getReturnAnalyticsByProduct = {
  input: z.object({
    startDate: z.string(),
    endDate: z.string(),
    types: z.array(z.nativeEnum(ReturnTypeEnum)),
    sort: ProductSortSchema.optional(),
    filters: z.array(ProductAnalyticsFilterSchema).optional(),
  }),
  output: z.object({
    products: z.array(ProductSchema),
    returnReasons: z.array(ReturnReasonSchema),
  }),
};

export type ReturnAnalyticsByProduct = z.infer<
  typeof getReturnAnalyticsByProduct.output
>;
