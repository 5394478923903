// extracted by mini-css-extract-plugin
export var colorSquare = "_d9-0";
export var danger = "_d9-5";
export var lightPink = "_d9-8";
export var neutralBlue = "_d9-7";
export var neutralYellow = "_d9-6";
export var normal = "_d9-1";
export var primary = "_d9-2";
export var primaryLight = "_d9-3";
export var solidBlack = "_d9-g";
export var solidGreen = "_d9-b";
export var solidLightBlue = "_d9-d";
export var solidNavy = "_d9-c";
export var solidOrange = "_d9-f";
export var solidPink = "_d9-e";
export var solidRed = "_d9-9";
export var solidYellow = "_d9-a";
export var success = "_d9-4";