// extracted by mini-css-extract-plugin
export var active = "_k-d";
export var blink = "_k-e";
export var container = "_k-5";
export var controls = "_k-0";
export var controlsRight = "_k-1";
export var fakeCursor = "_k-c";
export var hidden = "_k-b";
export var quillContainerSmall = "_k-7";
export var quillEditor = "_k-6";
export var quillEditorSmall = "_k-8";
export var quillFormatButtons = "_k-a";
export var quillToolbar = "_k-9";
export var removePadding = "_k-2";
export var state = "_k-3";
export var stateTitle = "_k-4";