// extracted by mini-css-extract-plugin
export var button = "_6-0";
export var buttonContent = "_6-a";
export var dontRestrainIconWidth = "_6-7";
export var dropdownButton = "_6-8";
export var iconContainer = "_6-6";
export var lg = "_6-4";
export var md = "_6-3";
export var pending = "_6-b";
export var sm = "_6-2";
export var spinner = "_6-9";
export var xl = "_6-5";
export var xs = "_6-1";