// extracted by mini-css-extract-plugin
export var activityItem = "_5v-0";
export var avatarContainer = "_5v-5";
export var commentImage = "_5v-9";
export var deleteButton = "_5v-f";
export var dotContainer = "_5v-b";
export var externalLink = "_5v-3";
export var icon = "_5v-c";
export var input = "_5v-7";
export var inputContainer = "_5v-6";
export var leftContent = "_5v-d";
export var messageBox = "_5v-8";
export var messageInputWrapper = "_5v-a";
export var rightContent = "_5v-e";
export var timelineContainer = "_5v-1";
export var timelineItem = "_5v-2";
export var timelineLine = "_5v-4";