import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { buyLabels } from "./schema/buy-labels";
import { createEodManifestForAll } from "./schema/create-eod-manifest-for-all";
import { createEodManifestForIds } from "./schema/create-eod-manifest-for-ids";
import { createManualFulfillmentGroups } from "./schema/create-manual-fulfillment-groups";
import { createPrintNodeAccount } from "./schema/create-printnode-account";
import { createShippingMethod } from "./schema/create-shipping-method";
import { createTaxIdentifier } from "./schema/create-tax-identifier";
import { deleteFulfillmentGroupView } from "./schema/delete-fulfillment-group-view";
import { deleteParcel } from "./schema/delete-parcel";
import { deleteShippingMethod } from "./schema/delete-shipping-method";
import { deleteTaxIdentifiers } from "./schema/delete-tax-identifiers";
import { duplicateShipments } from "./schema/duplicate-shipments";
import { editManualFulfillmentGroups } from "./schema/edit-manual-fulfillment-groups";
import { getAddressesFromName } from "./schema/get-address-from-name";
import { getBatchHistory } from "./schema/get-batch-history";
import { getBatchHistoryCount } from "./schema/get-batch-history-count";
import { getCarrierParcels } from "./schema/get-carrier-parcels";
import { getEndOfDayManifests } from "./schema/get-end-of-day-manifests";
import { getEodManifestOptions } from "./schema/get-eod-manifest-options";
import { getFulfillmentGroupSummaries } from "./schema/get-fulfillment-group-summaries";
import { getFulfillmentGroupSummaryCount } from "./schema/get-fulfillment-group-summary-count";
import { getFulfillmentGroupUniqueValues } from "./schema/get-fulfillment-group-unique-values";
import { getLatestOpenFulfillmentGroup } from "./schema/get-latest-open-fulfillment-group";
import { getMergeSuggestions } from "./schema/get-merge-suggestions";
import { getOriginAddresses } from "./schema/get-origin-addresses";
import { getOutboundBalance } from "./schema/get-outbound-balance";
import { getParcels } from "./schema/get-parcels";
import { getPrintNodeInfo } from "./schema/get-printnode-info";
import { getProductivityDashboardData } from "./schema/get-productivity-dashboard-data";
import { getRelevantFulfillmentGroups } from "./schema/get-relevant-fulfillment-groups";
import { getReplacementGroups } from "./schema/get-replacement-groups";
import { getTaxIdentifiers } from "./schema/get-tax-identifiers";
import { hasCarrierServices } from "./schema/has-carrier-services";
import { getInvoiceCsv } from "./schema/invoices/get-invoice-csv";
import { getInvoicePdf } from "./schema/invoices/get-invoice-pdf";
import { getTeamInvoices } from "./schema/invoices/get-team-invoices";
import { lookupOrder } from "./schema/lookup-order";
import { markAsFulfilled } from "./schema/mark-as-fulfilled";
import { markAsPrinted } from "./schema/mark-as-printed";
import { markAsPickedUp } from "./schema/mark-picked-up";
import { markAsReadyForPickup } from "./schema/mark-ready-for-pickup";
import { mergeFulfillmentGroups } from "./schema/merge-fulfillment-groups";
import { moveFulfillmentGroups } from "./schema/move-fulfillment-groups";
import { notifyMarketplace } from "./schema/notify-marketplace";
import { placeHolds } from "./schema/place-holds";
import { createPreset } from "./schema/presets/create-preset";
import { deletePreset } from "./schema/presets/delete-preset";
import { getPresets } from "./schema/presets/get-presets";
import { updatePreset } from "./schema/presets/update-preset";
import { printCommercialInvoices } from "./schema/print-commercial-invoice";
import { printDocument } from "./schema/print-document";
import { printLabels } from "./schema/print-labels";
import { printPackingSlip } from "./schema/print-packing-slip";
import { printPickList } from "./schema/print-pick-list";
import { printShortcutBarcodes } from "./schema/print-shortcut-barcodes";
import { printTest } from "./schema/print-test";
import { publishAutomationGroup } from "./schema/publish-automation-group";
import { rejectMergeSuggestion } from "./schema/reject-merge-suggestion";
import { releaseAllHolds } from "./schema/release-all-holds";
import { saveView } from "./schema/save-view";
import { setPublishedAutomations } from "./schema/set-published-automations";
import { splitFulfillmentGroup } from "./schema/split-fulfillment-group";
import { startBalanceReload } from "./schema/start-balance-reload";
import { unmergeFulfillmentGroups } from "./schema/unmerge-fulfillment-groups";
import { updateFulfillmentGroups } from "./schema/update-fulfillment-groups";
import { updateShippingAddress } from "./schema/update-shipping-address";
import { updateShippingMethod } from "./schema/update-shipping-method";
import { upsertParcels } from "./schema/upsert-parcels";
import { createViewFolder } from "./schema/views/create-view-folder";
import { deleteViewFolder } from "./schema/views/delete-view-folder";
import { getViewsAndFolders } from "./schema/views/get-views-and-folders";
import { saveViewsAndFolders } from "./schema/views/save-views-and-folders";
import { updateViewFolder } from "./schema/views/update-view-folder";
import { voidShipments } from "./schema/void-shipments";
import { watchFulfillmentGroups } from "./schema/watch-fulfillment-groups";

// Group related functions
const labelOperations = {
  buyLabels,
  printLabels,
  printCommercialInvoices,
  printPackingSlip,
  printPickList,
  printShortcutBarcodes,
  printDocument,
  printTest,
};

const mergeOperations = {
  mergeFulfillmentGroups,
  getMergeSuggestions,
  rejectMergeSuggestion,
  unmergeFulfillmentGroups,
};

const fulfillmentOperations = {
  createManualFulfillmentGroups,
  editManualFulfillmentGroups,
  splitFulfillmentGroup,
  updateFulfillmentGroups,
  getFulfillmentGroupSummaries,
  getFulfillmentGroupSummaryCount,
  getFulfillmentGroupUniqueValues,
  getLatestOpenFulfillmentGroup,
  placeHolds,
  releaseAllHolds,
  markAsFulfilled,
  markAsPrinted,
  markAsPickedUp,
  markAsReadyForPickup,
  notifyMarketplace,
  getRelevantFulfillmentGroups,
  getAddressesFromName,
  getReplacementGroups,
  watchFulfillmentGroups,
};

const manifestOperations = {
  createEodManifestForAll,
  createEodManifestForIds,
  getEndOfDayManifests,
  getEodManifestOptions,
};

const parcelOperations = {
  deleteParcel,
  upsertParcels,
  getParcels,
  getCarrierParcels,
};

const presetOperations = {
  createPreset,
  deletePreset,
  getPresets,
  updatePreset,
};

const invoiceOperations = { getInvoiceCsv, getInvoicePdf, getTeamInvoices };

const automationOperations = {
  publishAutomationGroup,
  setPublishedAutomations,
};

const viewOperations = {
  deleteFulfillmentGroupView,
  saveView,
  createViewFolder,
  updateViewFolder,
  deleteViewFolder,
  getViewsAndFolders,
  saveViewsAndFolders,
};

const shippingOperations = {
  createShippingMethod,
  deleteShippingMethod,
  updateShippingMethod,
  updateShippingAddress,
  voidShipments,
  duplicateShipments,
  getOriginAddresses,
  moveFulfillmentGroups,
};

const otherOperations = {
  createPrintNodeAccount,
  getBatchHistory,
  getBatchHistoryCount,
  getOutboundBalance,
  getPrintNodeInfo,
  hasCarrierServices,
  lookupOrder,
  startBalanceReload,
  getTaxIdentifiers,
  deleteTaxIdentifiers,
  createTaxIdentifier,
  getProductivityDashboardData,
};

type RpcDefinition = typeof labelOperations &
  typeof fulfillmentOperations &
  typeof manifestOperations &
  typeof parcelOperations &
  typeof presetOperations &
  typeof invoiceOperations &
  typeof automationOperations &
  typeof viewOperations &
  typeof mergeOperations &
  typeof shippingOperations &
  typeof otherOperations;

// Combine the groups into the final rpcDefinition
export const rpcDefinition: RpcDefinition = {
  ...labelOperations,
  ...fulfillmentOperations,
  ...manifestOperations,
  ...parcelOperations,
  ...presetOperations,
  ...invoiceOperations,
  ...automationOperations,
  ...viewOperations,
  ...mergeOperations,
  ...shippingOperations,
  ...otherOperations,
};

export type OutboundLabelsRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
