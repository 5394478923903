// extracted by mini-css-extract-plugin
export var attachment = "_ck-a";
export var emailContainer = "_ck-4";
export var emailContentPaddedContainer = "_ck-b";
export var emailHeader = "_ck-6";
export var emailInfoIcon = "_ck-9";
export var emailShortenedSummary = "_ck-e";
export var infoDropdown = "_ck-0";
export var infoRowBoundedContent = "_ck-d";
export var internal = "_ck-5";
export var merchant = "_ck-8";
export var messageInputContainer = "_ck-h";
export var normal = "_ck-7";
export var replyCard = "_ck-f";
export var shadowDomWrapper = "_ck-3";
export var subtleButton = "_ck-1";
export var subtleButtonIconContainer = "_ck-2";
export var toggleViewRepliesButton = "_ck-c";
export var wrappingText = "_ck-g";