// extracted by mini-css-extract-plugin
export var alternate = "_3t-h";
export var black = "_3t-3";
export var blue = "_3t-d";
export var blueLight = "_3t-c";
export var brand = "_3t-7";
export var error = "_3t-9";
export var gray = "_3t-6";
export var grayBlue = "_3t-b";
export var iconWrapper = "_3t-4";
export var indigo = "_3t-e";
export var orange = "_3t-g";
export var pink = "_3t-f";
export var primary = "_3t-0";
export var purple = "_3t-8";
export var success = "_3t-5";
export var warning = "_3t-a";
export var white = "_3t-1";
export var whiteSuccess = "_3t-2";