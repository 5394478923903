// extracted by mini-css-extract-plugin
export var active = "_5l-7";
export var backButton = "_5l-6";
export var collapseBar = "_5l-f";
export var collapseMode = "_5l-h";
export var dragHandle = "_5l-a";
export var dropdownIcon = "_5l-d";
export var hidden = "_5l-n";
export var iconContainer = "_5l-k";
export var iconSwapper = "_5l-m";
export var navItem = "_5l-4";
export var navItemIcon = "_5l-c";
export var navProfile = "_5l-5";
export var notificationDot = "_5l-l";
export var optionsContainer = "_5l-e";
export var primaryNav = "_5l-0";
export var primaryTab = "_5l-i";
export var secondaryNav = "_5l-1";
export var selected = "_5l-j";
export var settingsNav = "_5l-2";
export var settingsNavItems = "_5l-3";
export var show = "_5l-b";
export var sideNav = "_5l-g";
export var teamItem = "_5l-8";
export var userProfileItem = "_5l-9";