// extracted by mini-css-extract-plugin
export var arrows = "_4g-5";
export var decrementer = "_4g-9";
export var disabled = "_4g-3";
export var error = "_4g-1";
export var hiddenText = "_4g-a";
export var incrementer = "_4g-8";
export var input = "_4g-7";
export var inputWrapper = "_4g-0";
export var readonly = "_4g-2";
export var regular = "_4g-6";
export var small = "_4g-4";