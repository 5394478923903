// extracted by mini-css-extract-plugin
export var actionButton = "_d4-1";
export var actions = "_d4-0";
export var copyableField = "_d4-2";
export var icon = "_d4-3";
export var overflowVisible = "_d4-4";
export var pillWrapper = "_d4-5";
export var productDetails = "_d4-7";
export var productPillsContainer = "_d4-8";
export var productTopDetails = "_d4-9";
export var products = "_d4-6";
export var returnDetails = "_d4-a";
export var strong = "_d4-b";
export var variantQuantityRow = "_d4-c";