// extracted by mini-css-extract-plugin
export var chevron = "_5c-b";
export var disabled = "_5c-a";
export var hideBorder = "_5c-1";
export var icon = "_5c-c";
export var input = "_5c-0";
export var open = "_5c-8";
export var placeholder = "_5c-9";
export var regular = "_5c-4";
export var singleSelectInput = "_5c-2 _5c-0";
export var small = "_5c-3";
export var spinnerContainer = "_5c-e";
export var tag = "_5c-6";
export var tagsContainer = "_5c-7";
export var tagsInput = "_5c-5 _5c-0";
export var xSmall = "_5c-d";