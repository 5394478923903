// extracted by mini-css-extract-plugin
export var actionButton = "_5w-3e";
export var actionButtonText = "_5w-3d";
export var addIcon = "_5w-13";
export var addItem = "_5w-1f";
export var addNewItemButton = "_5w-8";
export var addNewItemButtonText = "_5w-9";
export var addNewItemIcon = "_5w-a";
export var addressSpace = "_5w-2s";
export var adjustmentBonus = "_5w-1i";
export var adjustmentFee = "_5w-1j";
export var buttonBar = "_5w-2v";
export var buttonContentNew = "_5w-2t";
export var buttonDropdown = "_5w-2x";
export var buttonDropdown2 = "_5w-2y";
export var buttonDropdownContainer = "_5w-2w";
export var card = "_5w-0";
export var changeAddress = "_5w-39";
export var chevron = "_5w-i";
export var clickAwayListener = "_5w-1u";
export var clickable = "_5w-g";
export var container = "_5w-2";
export var coverage = "_5w-25";
export var createLabelsImage = "_5w-2j";
export var deleteIcon = "_5w-2p";
export var deleteIconContainer = "_5w-2o";
export var dropdownContainer = "_5w-3n";
export var dropdownIcon = "_5w-3p";
export var dropdownIconOpen = "_5w-3q";
export var dropdownItem = "_5w-3r";
export var dropdownOption = "_5w-2z";
export var dropdownOptionText = "_5w-2u";
export var dropdownOverlay = "_5w-3t";
export var dropdownText = "_5w-3s";
export var dropdownTrigger = "_5w-3o";
export var editIcon = "_5w-f";
export var editNewItemsLink = "_5w-12";
export var emptyExchangeOrder = "_5w-1m";
export var error = "_5w-h";
export var expandButton = "_5w-3g";
export var expandButtonContainer = "_5w-3h";
export var expanded = "_5w-3i";
export var external = "_5w-24";
export var fraudRiskContainer = "_5w-2f";
export var fullHeight = "_5w-2i";
export var fullWidth = "_5w-2h";
export var greenReturnAdjustment = "_5w-1n";
export var greenReturnImage = "_5w-1r";
export var greenReturnSelectContainer = "_5w-1o";
export var greenReturnSelectTitle = "_5w-1p";
export var greenReturnText = "_5w-1q";
export var headerLayout = "_5w-30";
export var headerSubtitleSmall = "_5w-38";
export var hiddenValues = "_5w-3j";
export var hiddenValuesClosing = "_5w-3l";
export var icon = "_5w-3";
export var item = "_5w-k";
export var itemCard = "_5w-2k";
export var itemCommentImage = "_5w-6";
export var itemCommentImages = "_5w-7";
export var itemComments = "_5w-5";
export var itemHeader = "_5w-q";
export var itemImage = "_5w-l";
export var itemLink = "_5w-21";
export var itemMain = "_5w-m";
export var itemOption = "_5w-v";
export var itemOptions = "_5w-s";
export var itemOptionsText = "_5w-t";
export var itemPrice = "_5w-y";
export var itemPriceContainer = "_5w-16";
export var itemPriceEdit = "_5w-x";
export var itemPriceText = "_5w-17";
export var itemProperties = "_5w-n";
export var itemProperty = "_5w-o";
export var itemPropertyName = "_5w-p";
export var itemSearch = "_5w-1g";
export var itemSubtitle = "_5w-z";
export var itemSubtitleText = "_5w-10";
export var itemSummary = "_5w-18";
export var itemSummaryLine = "_5w-19";
export var itemSummaryValue = "_5w-1a";
export var itemTitle = "_5w-r";
export var itemTitleIcon = "_5w-u";
export var itemType = "_5w-w";
export var items = "_5w-4";
export var linkColor = "_5w-3a";
export var menuTitle = "_5w-1c";
export var modalButton = "_5w-1s";
export var modalContent = "_5w-26";
export var modalFooterRight = "_5w-1t";
export var newAddress = "_5w-j";
export var newItemButtonText = "_5w-e";
export var newItemHeader = "_5w-1k";
export var newItemHeaderTabs = "_5w-1l";
export var newItemShipping = "_5w-b";
export var newItems = "_5w-2r";
export var newItemsSection = "_5w-2q";
export var noTextWrap = "_5w-2l";
export var nonPriceContainer = "_5w-11";
export var optionContent = "_5w-1d";
export var optionTitle = "_5w-1b";
export var orderAndTags = "_5w-3v";
export var orderLink = "_5w-20";
export var orderNames = "_5w-3w";
export var orderNumber = "_5w-3f";
export var orderNumbers = "_5w-32";
export var originalPrice = "_5w-14";
export var paddedModalContent = "_5w-27";
export var priceSummaryTitle = "_5w-1e";
export var processButtons = "_5w-28";
export var product = "_5w-22";
export var productTitle = "_5w-3u";
export var question = "_5w-3c";
export var questions = "_5w-3b";
export var returnTypeModal = "_5w-1v";
export var returnTypeModalButton = "_5w-1y";
export var returnTypeModalHeader = "_5w-1x";
export var right = "_5w-15";
export var rightAligned = "_5w-1w";
export var riskReportIframe = "_5w-2g";
export var shippingText = "_5w-c";
export var shippingTextHeader = "_5w-d";
export var slideDown = "_5w-3k";
export var slideUp = "_5w-3m";
export var status = "_5w-23";
export var subsection = "_5w-2d";
export var subtitle = "_5w-36";
export var subtitleText = "_5w-37";
export var summaryElement = "_5w-1";
export var table = "_5w-1z";
export var tableCell = "_5w-2c";
export var tableDescription = "_5w-2b";
export var tableHeader = "_5w-2a";
export var tableHeaderGroup = "_5w-29";
export var tags = "_5w-34";
export var titleText = "_5w-35";
export var tooltipLink = "_5w-2n";
export var top = "_5w-33";
export var topRow = "_5w-31";
export var trackingNumbers = "_5w-2e";
export var trash = "_5w-2m";
export var voided = "_5w-1h";