// extracted by mini-css-extract-plugin
export var checkbox = "_2q-0";
export var circle = "_2q-3";
export var horizontalContainer = "_2q-7";
export var sm = "_2q-5";
export var switch0 = "_2q-2";
export var switchDisabled = "_2q-8";
export var switchEnabled = "_2q-1";
export var textSwitchContainer = "_2q-9";
export var textSwitchOption = "_2q-a";
export var textSwitchOptionSelected = "_2q-b";
export var verticalContainer = "_2q-6";
export var xs = "_2q-4";