// extracted by mini-css-extract-plugin
export var actionButton = "_ao-t";
export var buttonContainer = "_ao-0";
export var buttonContainer2 = "_ao-1";
export var buttonSection = "_ao-2";
export var details = "_ao-3";
export var dropdownItem = "_ao-5";
export var dropdownItemsContainer = "_ao-4";
export var fileInput = "_ao-r";
export var headerButton = "_ao-6";
export var icon = "_ao-7";
export var input = "_ao-8";
export var macroListItem = "_ao-9";
export var mainContent = "_ao-a";
export var microText = "_ao-b";
export var modal = "_ao-w";
export var optionsButton = "_ao-c";
export var paperclip = "_ao-s";
export var pillContent = "_ao-d";
export var preview = "_ao-e";
export var preview2 = "_ao-f";
export var quillEditor = "_ao-q";
export var selectedMacro = "_ao-g";
export var sidebar = "_ao-h";
export var sidebarHeader = "_ao-j";
export var sidebarList = "_ao-i";
export var sidebarListItem = "_ao-k";
export var sidebarSearch = "_ao-l";
export var snoozeDurationWrapper = "_ao-v";
export var statusPillContainer = "_ao-m";
export var tagsContainer = "_ao-n";
export var unselectedMacro = "_ao-o";
export var variable = "_ao-u";
export var wrapper = "_ao-p";