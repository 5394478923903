import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  AmazonServices,
  Carriers,
  FedexServices,
  UpsServices,
  UspsServices,
} from "../fulfillments/fulfillment-carriers-and-services";
import { SortingCriteria } from "../fulfillments/fulfillment-group";
import { ShopifyFulfillmentHoldReason } from "../fulfillments/shopify-fulfillment-schemas";
import { ConjunctionMode } from "../marketing/segments/segment-types";
import { SpecialRateEligibility } from "../outbound-labels/outbound-labels";
import { ParcelSchema } from "../outbound-labels/parcel";
import { WeightSchema } from "../outbound-labels/util";
import { TagSchema } from "../tag";
import { TimeUnit } from "../time";
import { supportedWaitForEventTypes } from "./common";
import { numberComparisonOperators } from "./expressions/number-expression";
import { textMatchOperators } from "./expressions/text-expression";
import { AutoHoldType, OmsActionType, OmsSettableFields } from "./oms/actions";
import {
  ListToListMatchType,
  OmsConditionType,
  StringToListMatchType,
} from "./oms/conditions";
import { SchemaType } from "./schemas/schemas";
import {
  ConditionDataSource,
  StepType,
  UpsellConditionSource,
  WaitTimeUnit,
} from "./steps";
import {
  categories,
  MarketingTrigger,
  OmsTrigger,
  OrderTrackingTrigger,
  ReturnTrackingTrigger,
  ReviewsTrigger,
} from "./triggers";

const baseStepSchema = z.object({
  customTitle: z.string().optional(),
  type: z.nativeEnum(StepType),
  id: z.string(),
});

const baseOmsActionStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.OMS_ACTION),
  actionType: z.nativeEnum(OmsActionType),
  field: z.nativeEnum(OmsSettableFields),
  nextId: z.string(),
});

const triggerStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.TRIGGER),
  schemaType: z.nativeEnum(SchemaType),
  category: z.enum(categories),
  key: z.union([
    z.nativeEnum(OrderTrackingTrigger),
    z.nativeEnum(ReturnTrackingTrigger),
    z.nativeEnum(MarketingTrigger),
    z.nativeEnum(ReviewsTrigger),
    z.nativeEnum(OmsTrigger),
  ]),
  nextId: z.string(),
  next: z.number(),
});

const waitStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.WAIT),
  numDays: z.number(),
  numSeconds: z.number().optional(),
  timeUnit: z.nativeEnum(WaitTimeUnit).default(WaitTimeUnit.DAYS).optional(),
  nextId: z.string(),
  next: z.number(),
});

const waitForEventStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.WAIT_FOR_EVENT),
  eventType: z.enum(supportedWaitForEventTypes),
  nextId: z.string(),
  next: z.number(),
});

const sendEmailStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.SEND_EMAIL),
  templateId: z.string(),
  emailAddressFieldName: z.string(),
  recipientNameFieldName: z.string(),
  nextId: z.string().optional(),
  next: z.number().optional(),
});

const sendSmsStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.SEND_SMS),
  templateId: z.string(),
  phoneNumberFieldName: z.string(),
  recipientNameFieldName: z.string(),
  nextId: z.string().optional(),
  next: z.number().optional(),
});

const numberComparisonExpressionSchema = z.object({
  type: z.literal("number_comparison"),
  field: z.union([z.string(), z.number()]),
  operator: z.enum(numberComparisonOperators),
  comparisonValue: z.number(),
});

const textMatchExpressionSchema = z.object({
  type: z.literal("text_match"),
  field: z.union([z.string(), z.number()]),
  operator: z.enum(["startsWith", "endsWith", "includes", "equals"]),
  matchValues: z.array(z.string()),
});

export const schemaBooleanExpressionSchema = z.lazy(() =>
  z.union([
    numberComparisonExpressionSchema,
    textMatchExpressionSchema,
    arrayPredicateExpressionSchema,
    booleanEvaluationExpressionSchema,
  ]),
);

export type SchemaBooleanExpressionType = z.infer<
  typeof schemaBooleanExpressionSchema
>;

const arrayPredicateExpressionSchema = z.object({
  type: z.literal("array_predicate"),
  field: z.union([z.string(), z.number()]),
  operator: z.enum(["some", "every"]),
  condition: z.lazy(() => schemaBooleanExpressionSchema),
}) as z.ZodType<{
  type: "array_predicate";
  field: string | number;
  operator: "some" | "every";
  condition: SchemaBooleanExpressionType;
}>;

const booleanEvaluationExpressionSchema = z.object({
  type: z.literal("boolean_evaluation"),
  field: z.union([z.string(), z.number()]),
});

const conditionExpressionSchema = z.discriminatedUnion("dataSource", [
  z.object({
    dataSource: z.literal(ConditionDataSource.TriggerData),
    schemaBooleanExpression: schemaBooleanExpressionSchema,
  }),
  z.object({
    dataSource: z.literal(ConditionDataSource.ExistingSegment),
    segmentIds: z.array(z.string()),
  }),
  z.object({
    dataSource: z.literal(ConditionDataSource.InlineSegment),
    inlineSegment: z.object({
      mode: z.nativeEnum(ConjunctionMode),
      conditions: z.array(z.any()), //TODO: Replace with queryConditionSchema (requires migration)
    }),
  }),
  z.object({ dataSource: z.literal(ConditionDataSource.OfferResponse) }),
]);

const conditionStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.CONDITION),
  expression: conditionExpressionSchema,
  nextTrueId: z.string(),
  nextTrue: z.number(),
  nextFalseId: z.string(),
  nextFalse: z.number(),
});

const upsellConditionStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.UPSELL_CONDITION),
  expression: z
    .object({
      source: z.nativeEnum(UpsellConditionSource),
      products: z
        .array(z.object({ id: z.string(), name: z.string() }))
        .optional(),
      collection: z.string().optional(),
    })
    .optional(),
  isDefault: z.boolean().optional(),
  nextTrueId: z.string().optional(),
  nextFalseId: z.string().optional(),
  name: z.string().optional(),
});

const upsellStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.UPSELL),
  name: z.string().optional(),
  upsellId: z.string(),
  nextId: z.string().optional(),
});

const downsellStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.DOWNSELL),
  name: z.string().optional(),
  downsellId: z.string(),
  nextId: z.string().optional(),
});

const doNothingStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.DO_NOTHING),
});

const setShippingServiceActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.SHIPPING_SERVICE),
  actionType: z.literal(OmsActionType.SET_SHIPPING_SERVICE),
  newShippingService: z.union([
    z.nativeEnum(FedexServices),
    z.nativeEnum(UpsServices),
    z.nativeEnum(UspsServices),
    z.nativeEnum(AmazonServices),
  ]),
  newShippingCarrier: z.nativeEnum(Carriers),
});

const setParcelActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.PARCEL),
  actionType: z.literal(OmsActionType.SET_PARCEL),
  parcel: ParcelSchema,
});

const setShippingServiceCheapestActionStepSchema =
  baseOmsActionStepSchema.extend({
    field: z.literal(OmsSettableFields.SHIPPING_SERVICE_CHEAPEST),
    actionType: z.literal(OmsActionType.SET_SHIPPING_SERVICE_CHEAPEST),
    servicesToChooseFrom: z.array(
      z.object({
        service: z.union([
          z.nativeEnum(FedexServices),
          z.nativeEnum(UpsServices),
          z.nativeEnum(UspsServices),
          z.nativeEnum(AmazonServices),
        ]),
        carrier: z.nativeEnum(Carriers),
      }),
    ),
    selectionCriteria: z.literal(SortingCriteria.CHEAPEST),
  });

const setShippingServiceFastestActionStepSchema =
  baseOmsActionStepSchema.extend({
    field: z.literal(OmsSettableFields.SHIPPING_SERVICE_FASTEST),
    actionType: z.literal(OmsActionType.SET_SHIPPING_SERVICE_FASTEST),
    servicesToChooseFrom: z.array(
      z.object({
        service: z.union([
          z.nativeEnum(FedexServices),
          z.nativeEnum(UpsServices),
          z.nativeEnum(UspsServices),
          z.nativeEnum(AmazonServices),
        ]),
        carrier: z.nativeEnum(Carriers),
      }),
    ),
    selectionCriteria: z.literal(SortingCriteria.FASTEST),
  });

const setPackingSlipTemplateActionStep = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.PACKING_SLIP_TEMPLATE),
  actionType: z.literal(OmsActionType.SET_PACKING_SLIP_TEMPLATE),
  packingSlipName: z.string(),
  packingSlipTemplateId: z.string(),
});

const addTagActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.TAG),
  actionType: z.literal(OmsActionType.ADD_TAG),
  tags: z.array(TagSchema),
});

const placeHoldActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.PLACE_HOLD),
  actionType: z.literal(OmsActionType.PLACE_HOLD),
  holdOptions: z.object({
    holdType: z.literal(AutoHoldType.Duration),
    duration: z.number(),
    timeUnit: z.nativeEnum(TimeUnit),
    holdReason: z.nativeEnum(ShopifyFulfillmentHoldReason),
    reasonNotes: z.string().nullish(),
  }),
});

const placeHoldUntilActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.PLACE_HOLD_UNTIL),
  actionType: z.literal(OmsActionType.PLACE_HOLD_UNTIL),
  holdOptions: z.object({
    holdType: z.literal(AutoHoldType.Date),
    date: z.coerce.date(),
    holdReason: z.nativeEnum(ShopifyFulfillmentHoldReason),
    reasonNotes: z.string().nullish(),
  }),
});

const setWeightActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.SET_WEIGHT),
  actionType: z.literal(OmsActionType.SET_WEIGHT),
  weight: WeightSchema,
});

const setAssignedUserActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.ASSIGNED_USER),
  actionType: z.literal(OmsActionType.SET_ASSIGNED_USER),
  userId: z.string(),
});

const setDutiesPayerActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.DUTIES_PAYER),
  actionType: z.literal(OmsActionType.SET_DUTIES_PAYER),
  ddp: z.boolean(),
});

const setTaxIdentifiersActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.TAX_IDENTIFIERS),
  actionType: z.literal(OmsActionType.SET_TAX_IDENTIFIERS),
  taxIdentifierIds: z.array(z.string()),
});

const shippingOptionsSchema = z.object({
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
  includeInsurance: z.boolean().nullish(),
  specialRateEligibility: z.nativeEnum(SpecialRateEligibility).nullish(),
});

const setShippingOptionsActionStepSchema = baseOmsActionStepSchema.extend({
  field: z.literal(OmsSettableFields.SHIPPING_OPTIONS),
  actionType: z.literal(OmsActionType.SET_SHIPPING_OPTIONS),
  shippingOptions: shippingOptionsSchema,
});

const baseOmsConditionStepSchema = baseStepSchema.extend({
  type: z.literal(StepType.OMS_CONDITION),
  conditionType: z.nativeEnum(OmsConditionType),
  nextTrueId: z.string().optional(),
  nextFalseId: z.string().optional(),
});

const omsOrderTagConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.ORDER_TAG),
  matchType: z.nativeEnum(ListToListMatchType).optional(),
  matchValues: z.array(z.string()),
  requireExactMatch: z.boolean().optional(),
});

const omsSelectedShippingConditionStepSchema =
  baseOmsConditionStepSchema.extend({
    conditionType: z.literal(OmsConditionType.SELECTED_SHIPPING),
    matchType: z.nativeEnum(ListToListMatchType).optional(),
    matchValues: z.array(z.string()),
    requireExactMatch: z.boolean().optional(),
  });

const omsSkuConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.SKU),
  matchType: z.nativeEnum(ListToListMatchType).optional(),
  matchValues: z.array(z.string()),
  requireExactMatch: z.boolean().optional(),
});

const omsProductNameConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.PRODUCT_NAME),
  matchType: z.nativeEnum(ListToListMatchType).optional(),
  matchValues: z.array(z.string()),
  requireExactMatch: z.boolean().optional(),
});

const omsItemQuantityConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.ITEM_QUANTITY),
  matchType: z.enum(numberComparisonOperators).optional(),
  matchValue: z.number(),
});

const omsPackageWeightOzConditionStepSchema = baseOmsConditionStepSchema.extend(
  {
    conditionType: z.literal(OmsConditionType.PACKAGE_WEIGHT_OZ),
    matchType: z.enum(numberComparisonOperators).optional(),
    matchValue: z.number(),
  },
);

const omsTotalValueConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.TOTAL_VALUE),
  matchType: z.enum(numberComparisonOperators).optional(),
  matchValue: z.number(),
});

const omsDestinationCountryConditionStepSchema =
  baseOmsConditionStepSchema.extend({
    conditionType: z.literal(OmsConditionType.DESTINATION_COUNTRY),
    matchType: z.nativeEnum(StringToListMatchType).optional(),
    matchValues: z.array(z.string()),
  });

const omsDestinationProvinceConditionStepSchema =
  baseOmsConditionStepSchema.extend({
    conditionType: z.literal(OmsConditionType.DESTINATION_PROVINCE),
    matchType: z.nativeEnum(StringToListMatchType).optional(),
    matchValues: z.array(z.string()),
  });

const omsDeliveryMethodConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.DELIVERY_METHOD),
  matchType: z.nativeEnum(StringToListMatchType).optional(),
  matchValues: z.array(z.string()),
});

const omsStreetAddressConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.STREET_ADDRESS),
  matchType: z.enum(textMatchOperators).optional(),
  matchValue: z.string(),
});

const omsSalesChannelConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.SALES_CHANNEL),
  matchType: z.nativeEnum(StringToListMatchType).optional(),
  matchValues: z.array(z.string()),
});

const omsProductTagConditionStepSchema = baseOmsConditionStepSchema.extend({
  conditionType: z.literal(OmsConditionType.PRODUCT_TAG),
  matchType: z.nativeEnum(ListToListMatchType).optional(),
  matchValues: z.array(z.string()),
  requireExactMatch: z.boolean().optional(),
});

export const omsActionStepSchema = z.discriminatedUnion("actionType", [
  setShippingServiceActionStepSchema,
  setParcelActionStepSchema,
  setShippingServiceCheapestActionStepSchema,
  setShippingServiceFastestActionStepSchema,
  setPackingSlipTemplateActionStep,
  addTagActionStepSchema,
  setShippingOptionsActionStepSchema,
  placeHoldActionStepSchema,
  placeHoldUntilActionStepSchema,
  setWeightActionStepSchema,
  setAssignedUserActionStepSchema,
  setDutiesPayerActionStepSchema,
  setTaxIdentifiersActionStepSchema,
]);

export const omsConditionStepSchema = z.discriminatedUnion("conditionType", [
  omsOrderTagConditionStepSchema,
  omsSelectedShippingConditionStepSchema,
  omsSkuConditionStepSchema,
  omsProductNameConditionStepSchema,
  omsItemQuantityConditionStepSchema,
  omsPackageWeightOzConditionStepSchema,
  omsTotalValueConditionStepSchema,
  omsDestinationCountryConditionStepSchema,
  omsDestinationProvinceConditionStepSchema,
  omsDeliveryMethodConditionStepSchema,
  omsStreetAddressConditionStepSchema,
  omsSalesChannelConditionStepSchema,
  omsProductTagConditionStepSchema,
]);

export const upsellFlowStepSchema = z.discriminatedUnion("type", [
  upsellConditionStepSchema,
  upsellStepSchema,
  downsellStepSchema,
  doNothingStepSchema,
]);

export const omsStepSchema = z.union([
  omsConditionStepSchema,
  omsActionStepSchema,
  doNothingStepSchema,
]);

export const marketingStepSchema = z.discriminatedUnion("type", [
  triggerStepSchema,
  waitStepSchema,
  waitForEventStepSchema,
  sendEmailStepSchema,
  sendSmsStepSchema,
  conditionStepSchema,
  doNothingStepSchema,
]);

export const stepSchema = z.union([
  omsStepSchema,
  marketingStepSchema,
  upsellFlowStepSchema,
]);

export const advancedFlowSchema = z.object({
  team: zExt.objectId(),
  name: z.string().optional(),
  enabled: z.boolean(),
  steps: z
    .array(stepSchema)
    .refine(
      (steps) => steps.filter((s) => s.type === StepType.TRIGGER).length === 1,
      "Flow must have exactly one trigger step",
    ),
  schemaType: z.nativeEnum(SchemaType),
  category: z.enum(categories),
  createdByUserId: zExt.objectId().optional(),
  createdAt: z.date().optional(),
  index: z.number().optional(),
  versionGroupId: zExt.objectId(),
  publishedAt: z.date().optional(),
  _id: zExt.objectId(),
  updatedAt: z.date().optional(),
  __v: z.number().optional(),
});

export type TriggerStep = z.infer<typeof triggerStepSchema>;
export type ConditionStep = z.infer<typeof conditionStepSchema>;
export type SendSmsStep = z.infer<typeof sendSmsStepSchema>;
export type AdvancedFlow = z.infer<typeof advancedFlowSchema>;
export type FlowStep = z.infer<typeof stepSchema>;
export type MarketingStep = z.infer<typeof marketingStepSchema>;
export type UpsellFlowStep = z.infer<typeof upsellFlowStepSchema>;
export type WaitStep = z.infer<typeof waitStepSchema>;
export type WaitForEventStep = z.infer<typeof waitForEventStepSchema>;
export type UpsellStep = z.infer<typeof upsellStepSchema>;
export type DownsellStep = z.infer<typeof downsellStepSchema>;
export type UpsellConditionStep = z.infer<typeof upsellConditionStepSchema>;
export type SendEmailStep = z.infer<typeof sendEmailStepSchema>;
export type DoNothingStep = z.infer<typeof doNothingStepSchema>;

export type NewAdvancedFlow = Omit<
  AdvancedFlow,
  "createdAt" | "updatedAt" | "schemaVersion" | "_id" | "versionGroupId"
>;
