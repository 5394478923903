// extracted by mini-css-extract-plugin
export var container = "_2r-0";
export var error = "_2r-9";
export var label = "_2r-5";
export var light = "_2r-6";
export var md = "_2r-3";
export var sm = "_2r-2";
export var thin = "_2r-8";
export var thinBold = "_2r-7";
export var vertical = "_2r-4";
export var xs = "_2r-1";