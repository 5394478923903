// extracted by mini-css-extract-plugin
export var container = "_8i-0";
export var content = "_8i-3";
export var customerTitle = "_8i-4";
export var empty = "_8i-5";
export var gray = "_8i-6";
export var header = "_8i-7";
export var info = "_8i-8";
export var loading = "_8i-2";
export var loadingCard = "_8i-9";
export var oneLine = "_8i-a";
export var personName = "_8i-e";
export var pillContainer = "_8i-b";
export var selected = "_8i-1";
export var text = "_8i-c";
export var twoLines = "_8i-d";
export var unread = "_8i-f";
export var unreadIndicator = "_8i-g";