// extracted by mini-css-extract-plugin
export var commandBarModal = "_1w-0";
export var commandIcon = "_1w-5";
export var menuItem = "_1w-8";
export var resultsSection = "_1w-7";
export var scrollContainer = "_1w-9";
export var searchIcon = "_1w-2";
export var searchInput = "_1w-3";
export var searchInputContainer = "_1w-1";
export var shortcutContainer = "_1w-4";
export var shortcutText = "_1w-6";