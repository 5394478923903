// extracted by mini-css-extract-plugin
export var active = "_4f-a";
export var activeIndicator = "_4f-9";
export var disabled = "_4f-b";
export var fullWidth = "_4f-2";
export var lg = "_4f-7";
export var md = "_4f-6";
export var redoButtonTabGroup = "_4f-0";
export var sm = "_4f-5";
export var tabButton = "_4f-4";
export var tabButtonsContainer = "_4f-1";
export var themeDestructive = "_4f-d";
export var themeNormal = "_4f-c";
export var themeSuccess = "_4f-e";
export var xl = "_4f-8";
export var xs = "_4f-3";