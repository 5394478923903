import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { LinkPrimaryTab } from "@redotech/redo-web/arbiter-components/navigation/primary/redo-primary-nav";
import { PageNavigationEntry } from "@redotech/redo-web/arbiter-components/navigation/redo-side-navigation";
import { SecondaryNavigationData } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav";
import { RedoSecondaryNavItem } from "@redotech/redo-web/arbiter-components/navigation/secondary/redo-secondary-nav-item";
import HomeIcon from "@redotech/redo-web/arbiter-icon/home-02.svg";
import HomeFilledIcon from "@redotech/redo-web/arbiter-icon/home-02_filled.svg";
import BarChart10FilledIcon from "@redotech/redo-web/icon-old/bar-chart-10-filled.svg";
import BarChart10Icon from "@redotech/redo-web/icon-old/bar-chart-10.svg";
import { memo } from "react";
import { useMatch } from "react-router-dom";
import { AnalyticsItems } from "./analytics";

export function useHomeNavigation(): PageNavigationEntry | null {
  const team = useRequiredContext(TeamContext);
  const returnsEnabled = team?.settings.returns?.enabled;
  const packageProtectionEnabled = team?.settings.packageProtection?.enabled;

  if (!returnsEnabled && !packageProtectionEnabled) {
    return null;
  }

  const urlPrefix = `/stores/${team._id}`;

  const primaryTab: LinkPrimaryTab = {
    id: "home",
    hasNotification: false,
    UnfilledIcon: HomeIcon,
    FilledIcon: HomeFilledIcon,
    type: "link",
    href: `${urlPrefix}/home`,
    pathRoot: `${urlPrefix}/home`,
    friendlyName: "Home",
  };

  const secondaryNavigationData: SecondaryNavigationData = {
    id: "home",
    name: "Home",
    NavContent: SecondaryHomeNavigation,
  };

  return { primaryTab, secondaryNavigationData };
}

const SecondaryHomeNavigation = memo(function SecondaryHomeNavigation({
  pathRoot,
}: {
  pathRoot: string;
}) {
  const team = useRequiredContext(TeamContext);

  const user = useRequiredContext(UserContext);

  const isAudienUser = user.roles.includes("audien-user");

  const urlPrefix = pathRoot;

  const returnsEnabled = team?.settings.returns?.enabled;
  const packageProtectionEnabled = team?.settings.packageProtection?.enabled;

  const anyChildSelected = useMatch(`${urlPrefix}/analytics/*`);

  return (
    <>
      {(returnsEnabled || packageProtectionEnabled) && (
        <RedoSecondaryNavItem
          action={{
            type: "link",
            href: `${urlPrefix}/${returnsEnabled ? "returns" : "claims"}`,
          }}
          FilledIcon={HomeFilledIcon}
          friendlyName="Overview"
          id="overview"
          UnfilledIcon={HomeIcon}
        />
      )}
      {!isAudienUser && (
        <RedoSecondaryNavItem
          action={{ type: "link", href: `${urlPrefix}/analytics/reports` }}
          anyChildSelected={!!anyChildSelected}
          FilledIcon={BarChart10FilledIcon}
          friendlyName="Analytics"
          id="analytics"
          UnfilledIcon={BarChart10Icon}
        >
          <AnalyticsItems pathRoot={pathRoot} />
        </RedoSecondaryNavItem>
      )}
    </>
  );
});
