import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { getAudience } from "./schema/audience/get-audience";
import { setCustomerSubscribed } from "./schema/audience/set-customer-subscribed";
import { setCustomerSuppressed } from "./schema/audience/set-customer-suppressed";
import { setAutomationEnabled } from "./schema/automations/set-automation-enabled";
import { generatePdfForInvoice } from "./schema/billing/generate-pdf-for-invoice";
import { getCurrentMarketingEmailInvoice } from "./schema/billing/get-current-marketing-email-invoice";
import { getCurrentMarketingSmsInvoice } from "./schema/billing/get-current-marketing-sms-invoice";
import { getEstimatedEmailCost } from "./schema/billing/get-estimated-email-cost";
import { getEstimatedSmsCost } from "./schema/billing/get-estimated-sms-cost";
import { getFinalizedServiceInvoices } from "./schema/billing/get-finalized-service-invoices";
import {
  getCampaignRecipientsCount,
  paginateCampaignRecipients,
} from "./schema/campaigns/campaign-recipient";
import { createCampaign } from "./schema/campaigns/create-campaign";
import { deleteCampaign } from "./schema/campaigns/delete-campaign";
import { duplicateCampaign } from "./schema/campaigns/duplicate-campaign";
import { getCampaignById } from "./schema/campaigns/get-campaign-by-id";
import { getCustomerGroups } from "./schema/campaigns/get-customer-groups";
import { getTeamCampaigns } from "./schema/campaigns/get-team-campaigns";
import { previewEmailTemplate } from "./schema/campaigns/preview-email-template";
import { sendTestCampaignSMS } from "./schema/campaigns/send-test-campaign-sms";
import { updateCampaign } from "./schema/campaigns/update-campaign";
import { createDiscount } from "./schema/discounts/create-discount";
import { deleteDiscount } from "./schema/discounts/delete-discount";
import { getDiscount } from "./schema/discounts/get-discount";
import { getDiscountsByTeam } from "./schema/discounts/get-discounts-by-team";
import { updateDiscount } from "./schema/discounts/update-discount";
import { getCities } from "./schema/geographic-units/get-cities";
import { getCountries } from "./schema/geographic-units/get-countries";
import { getStates } from "./schema/geographic-units/get-states";
import { getOutreachAnalytics } from "./schema/get-outreach-analytics";
import { getTeamPhoneNumber } from "./schema/get-team-phone-number-schema";
import { createGoogleAdsUserList } from "./schema/integrations/create-google-ads-user-list";
import { createMetaAdsAudience } from "./schema/integrations/create-meta-ads-audience";
import { getGoogleAdsCustomers } from "./schema/integrations/get-google-ads-customers";
import { getGoogleAdsIntegration } from "./schema/integrations/get-google-ads-integration";
import { getGoogleAdsOAuthURL } from "./schema/integrations/get-google-ads-oauth-url";
import { getGoogleAdsUserLists } from "./schema/integrations/get-google-ads-user-lists";
import { getMetaAdsAccounts } from "./schema/integrations/get-meta-ads-accounts";
import { getMetaAdsAudiences } from "./schema/integrations/get-meta-ads-audiences";
import { getMetaAdsIntegration } from "./schema/integrations/get-meta-ads-integration";
import { getMetaAdsOAuthURL } from "./schema/integrations/get-meta-ads-oauth-url";
import { saveConnectionsToGoogleAds } from "./schema/integrations/save-connections-to-google-ads";
import { saveConnectionsToMetaAds } from "./schema/integrations/save-connections-to-meta-ads";
import { metaLeadAds } from "./schema/meta-lead-ads/index";
import { createProductFilter } from "./schema/product-filters/create-product-filter";
import { deleteProductFilter } from "./schema/product-filters/delete-product-filter";
import { getCollectionById } from "./schema/product-filters/get-collection-by-id";
import { getProductFilterById } from "./schema/product-filters/get-product-filter-by-id";
import { getProductFiltersByTeam } from "./schema/product-filters/get-product-filters-by-team";
import { updateProductFilter } from "./schema/product-filters/update-product-filter";
import { createDynamicSegment } from "./schema/segments/create-dynamic-segment";
import { createStaticSegment } from "./schema/segments/create-static-segment";
import { deleteSegment } from "./schema/segments/delete-segment";
import { fetchSegmentById } from "./schema/segments/fetch-segment-by-id";
import { fetchTeamSegments } from "./schema/segments/fetch-team-segments";
import { getEstimatedCampaignRecipientCount } from "./schema/segments/get-estimated-campaign-recipient-count";
import { getSegmentCollections } from "./schema/segments/get-segment-collections";
import { getSegmentCount } from "./schema/segments/get-segment-count";
import { getSegmentMembers } from "./schema/segments/get-segment-members";
import { getSegmentProductTags } from "./schema/segments/get-segment-product-tags";
import { getSegmentProductVendors } from "./schema/segments/get-segment-product-vendors";
import { getTokenFieldOptions } from "./schema/segments/get-token-field-options";
import { refreshSegmentCount } from "./schema/segments/refresh-segment-count";
import { updateDynamicSegment } from "./schema/segments/update-dynamic-segment";
import { createSignupForm } from "./schema/signup-form/create-signup-form";
import { deleteSignupForm } from "./schema/signup-form/delete-signup-form";
import { duplicateSignupForm } from "./schema/signup-form/duplicate-signup-form";
import { getMarketingSubscriberCounts } from "./schema/signup-form/get-marketing-subscriber-counts";
import { getSignupFormById } from "./schema/signup-form/get-signup-form-by-id";
import { getSignupFormsByTeamId } from "./schema/signup-form/get-signup-forms-by-team-id";
import { unpublishSignupForm } from "./schema/signup-form/unpublish-signup-form";
import { updatePublishedAt } from "./schema/signup-form/update-published-at";
import { updateSignupForm } from "./schema/signup-form/update-signup-form";
import { verifyOrCreateNotOnSaleCollection } from "./schema/signup-form/verify-or-create-not-on-sale-collection";
import { getSmsTemplateById } from "./schema/sms-templates/get-sms-template-by-id";
import { sendTestSMSById } from "./schema/sms-templates/send-test-sms-by-id";
import { updateSmsTemplate } from "./schema/sms-templates/update-sms-template";
import { setPreferredSupportEmail } from "./schema/support-connection/set-preferred-support-email";

const automations = { setAutomationEnabled };

const signupForm = {
  createSignupForm,
  deleteSignupForm,
  getMarketingSubscriberCounts,
  getSignupFormById,
  getSignupFormsByTeamId,
  unpublishSignupForm,
  updatePublishedAt,
  updateSignupForm,
  verifyOrCreateNotOnSaleCollection,
  duplicateSignupForm,
};

const segmentMetadata = {
  refreshSegmentCount,
  getEstimatedCampaignRecipientCount,
  getTokenFieldOptions,
};

const billing = {
  getFinalizedServiceInvoices,
  getCurrentMarketingSmsInvoice,
  getCurrentMarketingEmailInvoice,
  generatePdfForInvoice,
  getEstimatedSmsCost,
  getEstimatedEmailCost,
};

const supportConnection = { setPreferredSupportEmail };

const productFilter = {
  createProductFilter,
  updateProductFilter,
  deleteProductFilter,
  getProductFiltersByTeam,
  getProductFilterById,
  getCollectionById,
};

const audience = { getAudience, setCustomerSubscribed, setCustomerSuppressed };

const fetchSegments = { fetchSegmentById, fetchTeamSegments };

const segmentFields = {
  getSegmentCollections,
  getSegmentProductTags,
  getSegmentProductVendors,
};

const segments1 = { updateDynamicSegment, deleteSegment };

const segments2 = { getSegmentCount, getSegmentMembers };

const segments3 = { createDynamicSegment, createStaticSegment };

const discounts = {
  createDiscount,
  updateDiscount,
  deleteDiscount,
  getDiscountsByTeam,
  getDiscount,
};

const campaigns1 = { updateCampaign };
const campaigns2 = { createCampaign };
const campaigns3 = {
  deleteCampaign,
  duplicateCampaign,
  getCampaignById,
  getTeamCampaigns,
  getCampaignRecipientsCount,
  paginateCampaignRecipients,
};

const geographicUnits = { getCountries, getStates, getCities };

const rest = {
  getOutreachAnalytics,
  getTeamPhoneNumber,
  getCustomerGroups,
  previewEmailTemplate,
  getSmsTemplateById,
  updateSmsTemplate,

  sendTestSMSById,
  sendTestCampaignSMS,
};

const integrations = {
  getGoogleAdsOAuthURL,
  getGoogleAdsIntegration,
  getGoogleAdsCustomers,
  getGoogleAdsUserLists,
  createGoogleAdsUserList,
  saveConnectionsToGoogleAds,
  getMetaAdsOAuthURL,
  getMetaAdsIntegration,
  getMetaAdsAccounts,
  getMetaAdsAudiences,
  createMetaAdsAudience,
  saveConnectionsToMetaAds,
  ...metaLeadAds,
};

type RpcDefinition = typeof signupForm &
  typeof automations &
  typeof audience &
  typeof rest &
  typeof campaigns1 &
  typeof campaigns2 &
  typeof campaigns3 &
  typeof segments1 &
  typeof segments2 &
  typeof segments3 &
  typeof segmentMetadata &
  typeof fetchSegments &
  typeof productFilter &
  typeof discounts &
  typeof segmentFields &
  typeof billing &
  typeof supportConnection &
  typeof geographicUnits &
  typeof integrations;

export const rpcDefinition: RpcDefinition = {
  ...automations,
  ...audience,
  ...signupForm,
  ...rest,
  ...campaigns1,
  ...campaigns2,
  ...campaigns3,
  ...segments1,
  ...segments2,
  ...segments3,
  ...segmentMetadata,
  ...fetchSegments,
  ...productFilter,
  ...discounts,
  ...segmentFields,
  ...billing,
  ...supportConnection,
  ...geographicUnits,
  ...integrations,
};

export type RedoMarketingRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
